import { Navigate } from 'react-router-dom';

const AdminRoute = ({ element, user, isAuthInitialized }) => {
  if (!isAuthInitialized) {
    return null;  // or some loading spinner
  }
  
  if (!user) {
    return <Navigate to="/happylogin" />;
  }

  return element;
};

export default AdminRoute;
