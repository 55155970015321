import React, { useState } from "react";
import "./Record01.css";

function Record01(props) {
    const { id, rdate, airTemp, waterTemp, score, badgeCount, streakFlag, timeBadge, newlyEarnedBadges, scoreDetails } = props;

    // State to track which record's details are shown
    const [expandedRecord, setExpandedRecord] = useState(null);

    // If the values are undefined, replace them with default values or placeholder text
    const safeLastBathDate = rdate ?
        `${rdate.getDate().toString().padStart(2, '0')}.${(rdate.getMonth() + 1).toString().padStart(2, '0')}.${rdate.getFullYear().toString().substr(-2)}` :
        'No date';

    const safeLastBathTime = rdate ? `${rdate.getHours().toString().padStart(2, '0')}:${rdate.getMinutes().toString().padStart(2, '0')}` : 'No time';

    // Convert to a number with one decimal place, and replace period with comma
    const airTempFormatted = parseFloat(airTemp).toFixed(0).replace('.', ',');
    const waterTempFormatted = parseFloat(waterTemp).toFixed(1).replace('.', ',');

    const badgeTooltip = Array.isArray(newlyEarnedBadges) ? newlyEarnedBadges.join(', ') : newlyEarnedBadges;

    const handleRowClick = (event) => {
        event.stopPropagation();
        if (scoreDetails) {
            setExpandedRecord(expandedRecord === id ? null : id);
        }
    };



    return (
        <div className={`record-container ${expandedRecord === id ? 'expanded' : ''}`} onClick={handleRowClick}>
    <div className="record">
      <div className="valign-text-middle">
        {streakFlag > 0 ? <img className="streak-icon-small" src="../img/streak_icon.svg" alt="Streak Icon" /> : null}
        {timeBadge > 0 ? <img className="streak-icon-small" src="../img/time_icon.svg" alt="Time Icon" /> : null}
        {badgeCount > 0 ? 
          <img 
            className="camera-icon-small" 
            src="../img/badges_icon.svg" 
            alt="Badge Icon" 
            title={badgeTooltip}
          /> 
          : null}
      </div>
      <div className="last-bath-date valign-text-middle helvetica-regular-normal-12px">{safeLastBathDate}</div>
      <div className="last-bath-time valign-text-middle helvetica-regular-normal-12px">{safeLastBathTime}</div>
      <div className="water-temp valign-text-middle helvetica-regular-normal-12px">{waterTempFormatted}°C</div>
      <div className="air-temp valign-text-middle helvetica-regular-normal-12px">{airTempFormatted}°C</div>
      <div className="score2 valign-text-middle helvetica-bold-black-12px">{score}</div>
      
      <img className="skillelinje" src={`../toptenimg/line-5.svg`} alt="Skillelinje" />
  
    </div>
    {expandedRecord === id && scoreDetails && (
      <div className="record-details">
        <div className="detail-item helvetica-regular-normal-vermilion-16px ">Poengfordeling</div>
        {scoreDetails?.baseScore > 0 && (
          <div className="detail-item">
            <span className="detail-name">Badepoeng</span>
            <span className="detail-value">{scoreDetails.baseScore}</span>
          </div>
        )}
        {scoreDetails?.streakBonus > 0 && (
          <div className="detail-item">
            <span className="detail-name">Bonus for streak</span>
            <span className="detail-value">{scoreDetails.streakBonus}</span>
          </div>
        )}
        {scoreDetails?.timeBadgeScore > 0 && (
          <div className="detail-item">
            <span className="detail-name">Timebadge</span>
            <span className="detail-value">{scoreDetails.timeBadgeScore}</span>
          </div>
        )}
        {/* Headline for Badge Scores */}
        {scoreDetails?.badgeDetails && Object.keys(scoreDetails.badgeDetails).length > 0 && (
          <div className="detail-item helvetica-regular-normal-vermilion-14px">Utmerkelser</div>
        )}

        {scoreDetails?.badgeDetails && Object.keys(scoreDetails.badgeDetails).length > 0 && (
      Object.entries(scoreDetails.badgeDetails).map(([badgeName, badgeScore]) => (

      
        <div key={badgeName} className="detail-item">
          <span className="detail-name">{badgeName}</span>
          <span className="detail-value">{badgeScore}</span>
        </div>
      ))
    )}
      </div>
    )}
    
        
    </div>
    );

}

export default Record01;