import React, { useState } from "react";
import "./SearchUser.css";

function SearchUser({ onSearch }) {
  const [searchTerm, setSearchTerm] = useState('');

const handleSearchChange = (e) => {
  const newSearchTerm = e.target.value;
  setSearchTerm(newSearchTerm);
  if (typeof onSearch === 'function') {
    onSearch(newSearchTerm);
  }
};


  return (
    <div className="sk-mobil">
      <div className="frame-621">
        <img className="iconsearch" src="../toptenimg/icon-search.svg" alt="icon/search" />
        <form onSubmit={(e) => e.preventDefault()} style={{flexGrow: 1}}>
          <input 
            type="text"
            placeholder="Søk på din favoritt Happy Belly"
            value={searchTerm}
            onChange={handleSearchChange}
            className="search-input-field helveticaneue-regular-normal-gray-12px"
          />
        </form>
      </div>
    </div>
  );
}

export default SearchUser;
