import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { db, auth } from '../TopplisteAutoLayout/firebase';
import Modal from 'react-modal';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faClock } from '@fortawesome/free-solid-svg-icons';
import "./TimebadgeAdmin.css";
import * as firebase from "firebase/app";

import {
    collection,
    doc,
    addDoc,
    getDocs,
    setDoc,
    updateDoc,
    query,
    orderBy,
    Timestamp,
    getFirestore,
    deleteDoc,
} from "firebase/firestore";
import { signOut, onAuthStateChanged } from "firebase/auth";

const TimebadgeAdmin = ({ user, setLoading, handleLogout }) => {
    

    const navigate = useNavigate();
    const [timebadges, setTimebadges] = useState([]);
    const [selectedBadge, setSelectedBadge] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [editingBadge, setEditingBadge] = useState({});
    const [selectedIds, setSelectedIds] = useState([]);




    const [formData, setFormData] = useState({
        minutes: '',
        score: '',
        start: ''
    });
    const [showNewBadgeRow, setShowNewBadgeRow] = useState(false);

    function handleCheckboxChange(id) {
    if (selectedIds.includes(id)) {
        setSelectedIds(prev => prev.filter(itemId => itemId !== id));
    } else {
        setSelectedIds(prev => [...prev, id]);
    }
}

async function handleDeleteSelected() {
    
    const timebadgesCollection = collection(db, "timebadges");

    // Create an array of deletion promises for each selected ID
    const deletePromises = selectedIds.map(id => {
        const badgeDoc = doc(timebadgesCollection, id);
        return deleteDoc(badgeDoc);
    });

    // Wait for all deletions to complete
    await Promise.all(deletePromises);

    // Update the local state by filtering out the deleted IDs
    setTimebadges(prevBadges => prevBadges.filter(badge => !selectedIds.includes(badge.id)));


    // Clear the selected IDs
    setSelectedIds([]);
}




    const enableEditing = (id, badgeData) => {
        setEditingBadge(badgeData);
        setTimebadges(prevState =>
            prevState.map(badge =>
                badge.id === id ? { ...badge, isEditing: true } : badge
            )
        );
    };

    const updateBadge = (id, field, value) => {
        setEditingBadge(prev => ({ ...prev, [field]: value }));
        setTimebadges(prevState =>
            prevState.map(badge =>
                badge.id === id ? { ...badge, [field]: value } : badge
            )
        );
    };

const saveChanges = async (id) => {
    let { id: omitId, isEditing, start, minutes, score, ...fieldsToUpdate } = editingBadge;

    // Ensure minutes and score are numbers
    const numericMinutes = Number(minutes);
    const numericScore = Number(score);

    // Initialize Firebase Timestamp
    let firebaseTimestamp;

    if (start instanceof Timestamp) {
        // If start is already a Firebase Timestamp, no conversion is needed.
        firebaseTimestamp = start;
    } else {
        // Otherwise, convert start to Firebase Timestamp
        const inputDate = new Date(start);
        if (isNaN(inputDate.getTime())) {
            console.error("Invalid start date", start);
            return;
        }
        firebaseTimestamp = Timestamp.fromDate(inputDate);
    }

    const badgeRef = doc(db, "timebadges", id);
    await updateDoc(badgeRef, { 
        start: firebaseTimestamp, 
        minutes: numericMinutes, 
        score: numericScore, 
        ...fieldsToUpdate 
    });

    setTimebadges(prevState =>
        prevState.map(badge =>
            badge.id === id ? { ...badge, isEditing: false } : badge
        )
    );
};


    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                const currentUserEmail = user.email;
                console.log('User email: ', currentUserEmail);
                if (currentUserEmail === "runar@finanger.com" || currentUserEmail === "dan.aksel@gmail.com" || user.email === "pometass@gmail.com") {
                    console.log('User is admin');
                    // User is admin, do nothing or log
                } else {
                    console.log('User is not admin');
                    // Not an admin
                    auth.signOut();
                    navigate("/happylogin");
                }
            } else {
                console.log('No user is signed in');
                navigate("/happylogin");
            }
        });

        // Cleanup
        return () => {
            unsubscribe();
        };
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const timebadgesQuery = query(
                    collection(db, "timebadges"),
                    orderBy("start", "desc")
                );
                const snapshot = await getDocs(timebadgesQuery);
                setTimebadges(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (auth.currentUser) {
            fetchData();
        }
    }, [auth.currentUser]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'minutes' && parseInt(value) > 120) {
            alert("Minutes cannot exceed 120");
            return;
        }
        setFormData((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation: Check if any fields are empty
    if (!formData.minutes || !formData.score || !formData.start) {
        alert("All fields are required!");
        return; // Exit the function early
    }

    let inputDate = new Date(formData.start);
    const firebaseTimestamp = Timestamp.fromDate(inputDate); // Convert to Firebase Timestamp

    if (selectedBadge) {
        const badgeRef = doc(db, "timebadges", selectedBadge.id);
        await updateDoc(badgeRef, {
            minutes: Number(formData.minutes),
            score: Number(formData.score),
            start: firebaseTimestamp, // Use Firebase Timestamp
        });
    } else {
        const newBadge = {
            minutes: Number(formData.minutes),
            score: Number(formData.score),
            start: inputDate,
            id: "temp-id", // Temporary ID, will be replaced
        };

        // Optimistically update the UI
        setTimebadges(prevState => [newBadge, ...prevState]);

        // Add to database
        const docRef = await addDoc(collection(db, "timebadges"), {
            minutes: Number(formData.minutes),
            score: Number(formData.score),
            start: firebaseTimestamp, // Use Firebase Timestamp
        });

        // Update the temporary ID with the real one
        setTimebadges(prevState => prevState.map(badge =>
            badge.id === "temp-id" ? { ...badge, id: docRef.id } : badge
        ));
    }

    // Reset form and any other states
    setShowNewBadgeRow(false);
    setFormData({ minutes: '', score: '', start: '' });
};



    return (
        <div className="timebadge-container">
<div className="admin-buttons">
    <Link to="/timebadge-admin" className="admin-button">
        <FontAwesomeIcon icon={faClock} />
        Timebadge
    </Link>

    <Link to="/admin-user" className="admin-button">
        <FontAwesomeIcon icon={faUser} />
        Members
    </Link>
    <Link to="/badge-admin" className="admin-button">
        <FontAwesomeIcon icon={faUser} />
        Badges
    </Link>

</div>
<div className="button-group">
  <button onClick={handleLogout}>Logout</button>
</div>

 <p className="helvetica-regular-normal-24px">TIMEBADGE ADMIN</p>

  <table className="admin-table">
    <thead>
      <tr>
        <th></th>
        <th>Min</th>
        <th>Score</th>
        <th>Start</th>
        <th></th>
      </tr>
    </thead>
    <tbody>

      {timebadges.map((badge) => (
        <tr key={badge.id}>
        <td>
          <input 
              type="checkbox" 
              onChange={() => handleCheckboxChange(badge.id)} 
              checked={selectedIds.includes(badge.id)}
          />
          </td>
          <td>
            {badge.isEditing ? 
              <input className="number-input" type="number" value={badge.minutes} onChange={(e) => updateBadge(badge.id, 'minutes', e.target.value)} />
              : badge.minutes}
          </td>
          <td>
            {badge.isEditing ? 
              <input className="number-input" type="number" value={badge.score} onChange={(e) => updateBadge(badge.id, 'score', e.target.value)} />
              : badge.score}
          </td>
          <td>
            {badge.isEditing ? 
<input
    type="datetime-local" 
    className="datetime-input" 
    value={
        badge.isEditing
        ? new Date(editingBadge.start?.toDate() || editingBadge.start).toISOString().substring(0, 16)
        : new Date(badge.start?.toDate()).toISOString().substring(0, 16)
    }
onChange={(e) => {
  // Convert local datetime to UTC datetime
  let date = new Date(e.target.value);
  let utcDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  
  // Create a Firestore Timestamp from the UTC date
  let firestoreTimestamp = Timestamp.fromDate(utcDate);

  setEditingBadge(prev => ({ ...prev, start: firestoreTimestamp }));
}}

/>



              : (badge.start instanceof Timestamp ? badge.start.toDate() : new Date(badge.start)).toLocaleString()}

          </td>
          <td>
            {badge.isEditing ?
              <span className="save-button" onClick={() => saveChanges(badge.id)}>Save</span>
              : <span className="edit-button" onClick={() => enableEditing(badge.id, badge)}>Edit</span>

}
          </td>
        </tr>
      ))}
          {showNewBadgeRow && (
  <tr>
  <td></td>
    <td><input type="number" name="minutes" max="120" value={formData.minutes} onChange={handleInputChange} className="number-input" required/></td>
    <td><input type="number" name="score" value={formData.score} onChange={handleInputChange} className="number-input" required/></td>
    <td><input type="datetime-local" name="start" value={formData.start} onChange={handleInputChange} className="datetime-input" required/></td>
    <td ><button className="add-button" onClick={handleSubmit}>Add</button></td>
</tr>
)}
    </tbody>
  </table>
  <div className="button-group">
  
  <button onClick={handleDeleteSelected}>Delete Selected</button>
  <button onClick={() => setShowNewBadgeRow(!showNewBadgeRow)}>Add New Record</button>

</div>

</div>

    );
};

export default TimebadgeAdmin;