import { initializeApp } from 'firebase/app';
import { getFirestore, enableMultiTabIndexedDbPersistence } from 'firebase/firestore';
import { getStorage, ref as firebaseRef, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getAuth, signInAnonymously } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDNCQu7ond-NAgvG8GmhG0sfi2w6cd0AL8",
  authDomain: "happybellies.firebaseapp.com",
  projectId: "happybellies",
  storageBucket: "happybellies.appspot.com",
  messagingSenderId: "1001680580291",
  appId: "1:1001680580291:web:446076291c985cb33afc76",
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export const auth = getAuth();
export const db = getFirestore();

export const authenticateAnonymously = () => {
    return signInAnonymously(auth);
};

// I'm renaming 'ref' from firebase/storage to 'firebaseRef' 
// to avoid any confusion or conflict with other possible 'ref' functions in React or elsewhere.
export { storage, firebaseRef as ref, uploadBytesResumable, getDownloadURL };

// Enable offline persistence with multi-tab synchronization
enableMultiTabIndexedDbPersistence(db)
  .catch((error) => {
    if (error.code === 'failed-precondition') {
      console.log('Multiple tabs open, persistence can only be enabled in one tab at a time.');
    } else if (error.code === 'unimplemented') {
      console.log('The current browser does not support all of the features required to enable persistence');
    }
  });
