import React, { useState, useEffect, useRef } from "react";
import { db, auth } from '../TopplisteAutoLayout/firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faUser, faClock } from '@fortawesome/free-solid-svg-icons';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { signOut, onAuthStateChanged } from "firebase/auth";

import { Link, useNavigate } from "react-router-dom";
import "./badgeadmin.css";
import * as firebase from "firebase/app";
import {
    collection,
    getDocs,
    addDoc,
    getDoc,
    query,
    orderBy,
    updateDoc,
    where,
    doc,
    setDoc,
    userDocRef,
    Timestamp,
} from "firebase/firestore";
import ReactModal from 'react-modal';

const BadgeAdmin = (user, setLoading, handleLogout) => {

    const navigate = useNavigate(); // Use the hook to get the navigate function

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                const currentUserEmail = user.email;
                console.log('User email: ', currentUserEmail);
                const isAdmin = localStorage.getItem('isAdmin') === 'true';
                if (isAdmin) {
                    console.log('User is admin');
                    // User is admin, do nothing or log
                } else {
                    console.log('User is not admin');
                    // Not an admin
                    signOut(auth); // Firebase v9 sign out
                    navigate("/happylogin");
                }
            } else {
                console.log('No user is signed in');
                navigate("/happylogin");
            }
        });

        // Cleanup
        return () => {
            unsubscribe();
        };
    }, [navigate]);

    const initialFormData = () => ({
        name: '',
        description: '',
        scoreFactor: '',
        uvIndex: { start: '', end: '' },
        waterTemp: { start: '', end: '' },
        airTemp: { start: '', end: '' },
        streak: { start: '', end: '' },
        time: { start: '', end: '' },
        date: { start: '', end: '' },
    });

    const [badges, setBadges] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formData, setFormData] = useState(initialFormData());
    const [selectedImage, setSelectedImage] = useState(null);
    const [imagePreview, setImagePreview] = useState('');
    const imageInputRef = useRef(null);

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (!user) navigate("/login"); // Adjust the path as per your routing setup
            else fetchBadges();
        });
    }, [navigate]);

    const [feedback, setFeedback] = useState({
        type: "",
        message: ""
    });


    useEffect(() => {
        if (feedback.message) {
            const timer = setTimeout(() => {
                setFeedback({ type: "", message: "" });
                // Close the modal after feedback is cleared if the feedback type is 'success'
                if (feedback.type === "success") {
                    closeModal();
                }
            }, 3000); // Clear feedback after 3 seconds

            return () => clearTimeout(timer); // Clear timeout if component  is unmounted
        }
    }, [feedback]);


    async function fetchBadges() {
        const snapshot = await getDocs(collection(db, "badges"));
        setBadges(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    }

    const [editedFields, setEditedFields] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const [field, subfield] = name.split('.');
        setEditedFields(prev => ({ ...prev, [name]: true })); // Track changes
        if (subfield) {
            setFormData(prev => ({
                ...prev,
                [field]: { ...prev[field], [subfield]: value },
            }));
        } else {
            setFormData(prev => ({ ...prev, [name]: value }));
        }
    };

    const formatBadgeName = (name) => {
        // Replace Norwegian characters with English equivalents and convert to lowercase at the beginning
        let formattedName = name.toLowerCase()
            .replace(/ø/g, 'o')
            .replace(/æ/g, 'ae')
            .replace(/å/g, 'a');

        // Normalize string to remove accents/diacritics
        formattedName = formattedName.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        // Replace spaces with dashes and remove non-word characters (excluding dashes)
        return `badge-${formattedName.replace(/\s+/g, '-').replace(/[^\w-]/g, '')}`;
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.name || !formData.description || !formData.scoreFactor) {
            setFeedback({ message: 'Name, description, and score factor are required.', type: 'error' });
            return;
        }

        const formattedName = formatBadgeName(formData.name);
        const storage = getStorage();

        let dataToSubmit = {
            ...formData,
            scoreFactor: Number(formData.scoreFactor) // Ensure scoreFactor is a number
        };

        // If updating (not adding), filter out unchanged fields
        if (formData.id) {
            dataToSubmit = Object.entries(dataToSubmit).reduce((acc, [key, value]) => {
                if (editedFields[key] || key === 'id' /* Always include id and other essential fields */ ) {
                    acc[key] = value;
                }
                return acc;
            }, {});
        }

        // Process each field to ensure map fields with 'start' and 'end' are only included if they have content
        Object.keys(dataToSubmit).forEach(key => {
            const value = dataToSubmit[key];
            if (typeof value === 'object' && value !== null) {
                // Check if the map field (e.g., uvIndex, waterTemp) has both 'start' and 'end' as empty
                if ((value.start === '' || value.start === null) && (value.end === '' || value.end === null)) {
                    delete dataToSubmit[key]; // Remove the map field from dataToSubmit if it's empty
                } else {
                    // Convert 'start' and 'end' values to appropriate types (number or Timestamp)
                    if (key !== 'time' && key !== 'date') { // Assuming 'time' and 'date' need to be handled as Timestamps
                        if (value.start !== '') value.start = Number(value.start);
                        if (value.end !== '') value.end = Number(value.end);
                    } else {
                        // Convert 'start' and 'end' values to Timestamp for 'time' and 'date'
                        if (value.start !== '') {
                            const startDate = new Date(value.start); // Convert 'start' to a Date object
                            if (!isNaN(startDate.getTime())) { // Check if 'start' is a valid Date
                                value.start = Timestamp.fromDate(startDate); // Convert 'start' to Timestamp
                            } else {
                                console.error('Invalid date format for value.start:', value.start);
                            }
                        }

                        if (value.end !== '') {
                            const endDate = new Date(value.end); // Convert 'end' to a Date object
                            if (!isNaN(endDate.getTime())) { // Check if 'end' is a valid Date
                                value.end = Timestamp.fromDate(endDate); // Convert 'end' to Timestamp
                            } else {
                                console.error('Invalid date format for value.end:', value.end);
                            }
                        }
                    }

                }
            }
        });

        // Filter out empty fields
        dataToSubmit = Object.entries(dataToSubmit).reduce((acc, [key, value]) => {
            if (value != null && !(typeof value === 'object' && Object.keys(value).length === 0)) {
                acc[key] = value;
            }
            return acc;
        }, {});

        // Process Image Upload
        if (selectedImage) {
            const storage = getStorage();
            const formattedName = formatBadgeName(formData.name); // Ensure this uses your naming logic
            const originalImageRef = ref(storage, `badges/${formattedName}.jpg`);

            // Upload the original image
            const uploadOriginal = uploadBytesResumable(originalImageRef, selectedImage);
            await uploadOriginal.then(async (snapshot) => {
                console.log('Original image uploaded');
                // You might want to get and use the URL here or simply confirm upload
            });

            // Create a blurred version of the image and upload it
            createBlurredImage(selectedImage, async (blurredImageBlob) => {
                const blurredImageRef = ref(storage, `badges/blurred/${formattedName}_blur.jpg`);
                const uploadBlurred = uploadBytesResumable(blurredImageRef, blurredImageBlob);
                await uploadBlurred.then(async (snapshot) => {
                    console.log('Blurred image uploaded');
                    // Similarly, you might want to get and use the URL here
                });
            });
        }

        // Determine if we're adding a new badge or updating an existing one
        const badgeRef = formData.id ? doc(db, "badges", formData.id) : doc(collection(db, "badges"), formattedName);

        try {
            if (formData.id) {
                await updateDoc(badgeRef, dataToSubmit);
            } else {
                await setDoc(badgeRef, dataToSubmit);
            }
            setFeedback({ message: 'Badge successfully saved!', type: 'success' });
        } catch (error) {
            setFeedback({ message: error.message, type: 'error' });
        }

        setIsModalOpen(false);
        fetchBadges();
        setFormData(initialFormData()); // Reset form state
        setImagePreview('');
        setSelectedImage(null); // Clear selected image
    };


    // Function to open the modal for editing an existing badge
    const openModalForEdit = (badge) => {
        if (badge.id) {
            // Exclude 'id' field from the badge object when editing
            const { id, ...formattedBadge } = badge;
            setFormData(formattedBadge);
            setImagePreview(`https://firebasestorage.googleapis.com/v0/b/happybellies.appspot.com/o/badges%2F${badge.id}.jpg?alt=media`);
        } else {
            // Set the badge data as it is for adding a new badge
            setFormData(badge);
            setImagePreview(''); // Reset image preview for a new badge
        }
        setIsModalOpen(true);
    };

    // Function to open the modal for adding a new badge
    const openModalForAdding = () => {
        setFormData(initialFormData()); // Reset form data to initial state
        setImagePreview(''); // Reset image preview to blank
        setSelectedImage(null); // Clear any selected image
        setIsModalOpen(true); // Open the modal
    };


    const closeModal = () => {
        setIsModalOpen(false);
        setFormData(initialFormData());
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result); // Update the preview with the selected image
            };
            reader.readAsDataURL(file);
            setSelectedImage(file); // Keep the original file for upload
        }
    };

    const createBlurredImage = (imageFile, callback) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            const img = new Image();
            img.onload = () => {
                // Slightly larger canvas to accommodate the blur without black edges
                const padding = 20; // This padding should be at least as large as the blur radius
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = img.width + padding * 2;
                canvas.height = img.height + padding * 2;

                // Fill the canvas with white color to match the image edges
                ctx.fillStyle = "#FFFFFF"; // Use white background
                ctx.fillRect(0, 0, canvas.width, canvas.height);

                // Draw the image in the center with padding around it
                ctx.drawImage(img, padding, padding, img.width, img.height);

                // Apply the blur filter
                ctx.filter = 'blur(20px)';
                // Redraw the image over itself with the blur effect
                ctx.drawImage(canvas, 0, 0);

                canvas.toBlob((blob) => {
                    callback(blob); // Return the blurred image as a Blob
                }, imageFile.type);
            };
            img.src = event.target.result;
        };
        reader.readAsDataURL(imageFile);
    };




    return (

        <div className="admin-container">
<div className="admin-buttons">
    <Link to="/timebadge-admin" className="admin-button"><FontAwesomeIcon icon={faClock} />Timebadge</Link>
    <Link to="/admin-user" className="admin-button"><FontAwesomeIcon icon={faUser} />Members</Link>
    <Link to="/badge-admin" className="admin-button"><FontAwesomeIcon icon={faUser} />Badges</Link>
</div>
    <h2 className="admin-header">Badge Administration</h2>
   <button className="admin-button" onClick={() => {
    setFormData(initialFormData()); // Reset form data to its initial state
    setImagePreview(''); // Clear the image preview
    setSelectedImage(null); // Clear the selected image state
    setIsModalOpen(true); // Open the modal
}}>Add New Badge</button>

    <div className="badge-list">
        {badges.map(badge => (
            <div key={badge.id} className="badge-entry">
                <img 
                    src={`https://firebasestorage.googleapis.com/v0/b/happybellies.appspot.com/o/badges%2F${badge.id}.jpg?alt=media`} 
                    alt={badge.name} 
                    className="badge-image"
                />
                <div className="badge-info">
                    <p>{badge.name}<br />{badge.description}<br />Score: {badge.scoreFactor}</p>
                    <button className="edit-button" onClick={() => openModalForEdit(badge)}>Edit</button>
                </div>
            </div>
        ))}
    </div>



            <ReactModal 
                isOpen={isModalOpen} 
                onRequestClose={closeModal} 
                className="Modal" 
                overlayClassName="Overlay">
                <div className="modal-content">
                  <div className="avatar-placeholder" style={{ backgroundImage: `url("${imagePreview || 'default_placeholder_image_path_if_needed'}")` }}>
    <input type="file" accept="image/*" hidden ref={imageInputRef} onChange={handleImageChange} />
    <FontAwesomeIcon icon={faCamera} onClick={() => imageInputRef.current.click()} style={{ color: 'white', fontSize: '24px' }} />
</div>

                <form onSubmit={handleSubmit} className="modal-form">
                    {/* Dynamically generate form inputs for criteria */}
                    {/* Static fields */}
                   <div className="criteria-fields">
                    <div className="criteria-field">
                    <label className="form-label">Name: <input className="form-input" name="name" value={formData.name} onChange={handleInputChange} /></label>
                    </div>
            <div className="criteria-field"><label className="form-label">Score: <input className="form-input" type="number" name="scoreFactor" value={formData.scoreFactor} onChange={handleInputChange} /></label>
            </div>
            </div>
                    <label className="form-label">Description: <input className="form-input" name="description" value={formData.description} onChange={handleInputChange} /></label>
                    
                    {/* Criteria fields */}
                 {Object.keys(initialFormData()).filter(key => typeof formData[key] === 'object').map(criteriaKey => (
    <div key={criteriaKey} className="criteria-item">
        <h4>{criteriaKey}</h4>
        <div className="criteria-fields">
            <div className="criteria-field">
                <label>Start:
                    <input
                        className="form-input"
                        type={criteriaKey === 'time' || criteriaKey === 'date' ? 'datetime-local' : 'text'}
                        name={`${criteriaKey}.start`}
                        value={formData[criteriaKey].start}
                        onChange={handleInputChange}
                    />
                </label>
            </div>
            <div className="criteria-field">
                <label>End:
                    <input
                        className="form-input"
                        type={criteriaKey === 'time' || criteriaKey === 'date' ? 'datetime-local' : 'text'}
                        name={`${criteriaKey}.end`}
                        value={formData[criteriaKey].end}
                        onChange={handleInputChange}
                    />
                </label>
            </div>
        </div>
    </div>

))}

{feedback.message && (
    <div className={`feedback-${feedback.type}`}>
        {feedback.message}
    </div>
)}


                    <button type="submit" className="save-button">Save Badge</button>
                    <button onClick={closeModal} className="close-button">Close</button>
                </form>
                </div>
            </ReactModal>
        </div>
    );
};

export default BadgeAdmin;