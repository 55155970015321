import React, { useState, useEffect } from "react";
import "./badgegalleri.css";
import { db } from '../TopplisteAutoLayout/firebase';
import { getDocs, query, where, doc, getDoc, addDoc, onSnapshot, collection, orderBy, limit } from "firebase/firestore";
import { useLocation,Link } from "react-router-dom";
import Top from "../Top";
import Modal from 'react-modal';
import MyModal from '../../components/MyModal';
import "../ToppListeAutoLayout/TopplisteAutoLayout.css";


export const BadgeGallery = () => {
  const [badges, setBadges] = useState([]);
  const [loading, setLoading] = useState(true);
  const [earnedBadges, setEarnedBadges] = useState([]);
  const location = useLocation();
  const rfid = location.state.rfid;
  const decodedAlias = location.state.decodedAlias;
  const alias = location.state.alias;
  const selectedYear = location.state.selectedYear; 

 useEffect(() => {
    const fetchEarnedBadges = async () => {
        if (rfid && selectedYear) {
            const userCollection = selectedYear === new Date().getFullYear() ? 'users' : `users-${selectedYear}`;
            const userDoc = await getDoc(doc(db, userCollection, rfid));
            const userData = userDoc.data();
            setEarnedBadges(userData.earned_badges || []);
        }
    };

    fetchEarnedBadges();
}, [rfid, selectedYear]); 


  useEffect(() => {
    // Fetch badges from Firestore
    const fetchBadges = async () => {
      const badgesCollection = collection(db, "badges");
      const badgesSnapshot = await getDocs(badgesCollection);
      const badgesList = badgesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setBadges(badgesList);
      setLoading(false);
    };

    fetchBadges();
  }, []);

  const [modalIsOpen, setIsOpen] = useState(false);
  
  if (loading) {
    return <div>Loading...</div>;
  }
  

  const openModal = () => {
    setIsOpen(true);
  }

  const closeModal = () => {
    setIsOpen(false);
  }


  return (
    <div className="bruker-autolayout screen">
    <MyModal modalIsOpen={modalIsOpen} closeModal={closeModal} /><Top openModal={openModal} />
    
    <div className="badge-gallery">
    <div className="breadcrumbs"><div className="hjem-toppliste helvetica-regular-normal-black-12px"><Link to="/" title="Going home">Hjem</Link>  > <Link to="/top-ten-list" title="Going to top-ten">Toppliste</Link> > <Link to={`/top-ten-list/${alias}`}> {decodedAlias}</Link>
 > Utmerkelser</div></div>
      <div className="galleri-badges">
       <div className="headertext">
  <p className="umerkelser-mons">
    <span className="span">Utmerkelser</span>
    <span className="text-wrapper-2">
      {" "}
      <br />
      {decodedAlias || "User"}
    </span>
  </p>
</div>
        <div className="grid">
           {badges.map(badge => (
  <div className="div" key={badge.id}>
   <img 
  className="img" 
  style={{ opacity: earnedBadges.includes(badge.id) ? 1 : 0.5 }}
  alt={`Badge ${badge.name}`} 
  src={earnedBadges.includes(badge.id) ? 
       `https://firebasestorage.googleapis.com/v0/b/happybellies.appspot.com/o/badges%2F${badge.id}.jpg?alt=media` : 
       `https://firebasestorage.googleapis.com/v0/b/happybellies.appspot.com/o/badges%2Fblurred%2F${badge.id}_blur.jpg?alt=media`} 
/>

    <div className="text-badge">
      <div className="title-badge" style={{
  filter: earnedBadges.includes(badge.id) ? 'none' : 'blur(8px)',
  opacity: earnedBadges.includes(badge.id) ? 1 : 0.5
}}>{badge.name || 'Unnamed Badge'}
</div>
      <div className="description">{badge.description}</div>
    </div>
  </div>
))}
        </div>
      </div>
    </div>
  </div>
  );
};
export default BadgeGallery;
