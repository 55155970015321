import React, { useState, useEffect, useRef } from "react";
import { db, auth } from '../TopplisteAutoLayout/firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faUser, faClock } from '@fortawesome/free-solid-svg-icons';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { signOut, onAuthStateChanged } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import "./AdminUser.css";
import * as firebase from "firebase/app";
import {
    collection,
    getDocs,
    addDoc,
    getDoc,
    query,
    orderBy,
    updateDoc,
    where,
    doc,
    setDoc,
    userDocRef,
} from "firebase/firestore";
import ReactModal from 'react-modal';

const AdminUser = ({ user, setLoading, handleLogout }) => {

    const navigate = useNavigate(); // Use the hook to get the navigate function

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                const currentUserEmail = user.email;
                console.log('User email: ', currentUserEmail);
                const isAdmin = localStorage.getItem('isAdmin') === 'true';
                if (isAdmin) {
                    console.log('User is admin');
                    // User is admin, do nothing or log
                } else {
                    console.log('User is not admin');
                    // Not an admin
                    signOut(auth); // Firebase v9 sign out
                    navigate("/happylogin");
                }
            } else {
                console.log('No user is signed in');
                navigate("/happylogin");
            }
        });

        // Cleanup
        return () => {
            unsubscribe();
        };
    }, [navigate]);


    const [alias, setAlias] = useState('');
    const [userid, setUserId] = useState('');
    const [pinCode, setPincode] = useState('');
    const [users, setUsers] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const usersCollection = collection(db, 'users');
    const imageInput = useRef(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [membershipPaid, setMembershipPaid] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [initialValues, setInitialValues] = useState({});
    const [feedback, setFeedback] = useState({
        type: "",
        message: ""
    });


    useEffect(() => {
        if (feedback.message) {
            const timer = setTimeout(() => {
                setFeedback({ type: "", message: "" });
                // Close the modal after feedback is cleared if the feedback type is 'success'
                if (feedback.type === "success") {
                    closeModal();
                }
            }, 3000); // Clear feedback after 3 seconds

            return () => clearTimeout(timer); // Clear timeout if component  is unmounted
        }
    }, [feedback]);


    const openModal = (action) => {
        // If the action is 'add', reset the form
        if (action === 'add') {
            resetForm();
            setImagePreview(null);
        }

        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle the form submission logic here.
        // This can involve sending data to the Firebase database.
        closeModal();
    }
    const [membership, setMembership] = useState(false);

    // The onChange handler function
    const handleCheckboxChange = (e) => {
        setMembership(e.target.checked);
    };

    const [image, setImage] = useState(null);
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImage(file); // This sets the actual image file object to state

            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result); // This will set the image preview URL
            };
            reader.readAsDataURL(file);
        }
    };

    const [selectedUser, setSelectedUser] = useState(null);

    useEffect(() => {
        console.log("useEffect triggered", editMode, selectedUser)
        if (editMode && selectedUser && !initialValues.alias) {
            console.log("Setting initial values", selectedUser);
            setAlias(selectedUser.alias);
            setImage(selectedUser.image);
            setUserid(selectedUser.userid);
            setPincode(selectedUser.pinCode);
            setMembership(selectedUser.membership);

            // Set the initial values for comparison during editing
            setInitialValues({
                alias: selectedUser.alias,
                image: selectedUser.image,
                userid: selectedUser.userid,
                pinCode: selectedUser.pinCode,
                membership: selectedUser.membership
            });
        }
    }, [editMode, selectedUser, initialValues.alias]);



    const handleEditClick = (user) => {
        // Set individual states
        setAlias(user.alias);
        setImage(user.image);
        setUserId(user.userid);
        setPincode(user.pinCode);
        setMembership(user.membership);

        // Set the selectedUser state
        setSelectedUser(user);

        // Set the initial values state here
        setInitialValues({
            alias: user.alias,
            image: user.image,
            userid: user.userid,
            pinCode: user.pinCode,
            membership: user.membership
        });

        const firebaseImageUrl = `https://firebasestorage.googleapis.com/v0/b/happybellies.appspot.com/o/profileImages%2F${user.userid}.jpg?alt=media`;
        setImagePreview(firebaseImageUrl); // Set the image preview for edit mode based on Firebase URL pattern
        setEditMode(true);
        openModal();
    };


    const resetForm = () => {
        setAlias('');
        setImage('');
        setUserId('');
        setPincode('');
        setMembership(false);
        setEditMode(false);
    };


    const getChangedFields = () => {
        let changedFields = {};
        console.log("Current alias:", alias);
        console.log("Initial alias:", initialValues.alias);


        if (alias && initialValues.alias && alias !== initialValues.alias) {
            changedFields.alias = alias;
        }

        // Check both the current and initial image values before comparing their names.
        if (image && initialValues.image && image.name !== initialValues.image.name) {
            changedFields.image = image;
        }

        if (userid && initialValues.userid && userid !== initialValues.userid) {
            changedFields.userid = userid;
        }

        if (pinCode && initialValues.pinCode && pinCode !== initialValues.pinCode) {
            changedFields.pinCode = pinCode;
        }

        if (membership !== initialValues.membership) {
            changedFields.membership = membership;
        }


        return changedFields;
    }


    const handleSave = async (event) => {
        event.preventDefault();

        if (!alias || !userid || !pinCode) {
            setFeedback({ type: "error", message: "All fields are required!" });
            return;
        }
        // If in add mode and image is not provided
        if (!editMode && !image) {
            setFeedback({ type: "error", message: "Image is required!" });
            return;
        }

        if (editMode) {
            const changes = getChangedFields();
            console.log("Detected changes:", changes);

            if (Object.keys(changes).length) {
                // Query to find the document where userid matches the given userid
                const userQuery = query(usersCollection, where("userid", "==", userid));
                const userSnapshot = await getDocs(userQuery);

                if (!userSnapshot.empty) {
                    const userDoc = userSnapshot.docs[0]; // Get the first document
                    const userDocRef = doc(usersCollection, userDoc.id); // Use the actual document ID

                    try {
                        await updateDoc(userDocRef, changes); // Update specific fields

                        // Update the local users state
                        setUsers(prevUsers => {
                            return prevUsers.map(user => {
                                if (user.userid === userid) {
                                    return { ...user, ...changes }; // Merge old user data with the updated fields
                                }
                                return user;
                            });
                        });

                        setFeedback({ type: "success", message: "User updated successfully!" });
                        
                    } catch (error) {
                        console.error("Update Error:", error);
                        setFeedback({ type: "error", message: "Error updating user." });
                    }
                } else {
                    setFeedback({ type: "error", message: `No user found with ID ${userid} to update.` });
                }
            } else {
                setFeedback({ type: "success", message: "No changes detected. Nothing to update." });
            }
        } else {
            // Handle the logic for adding a new user

            // Check if userid is unique
            const userQuery = query(usersCollection, where("userid", "==", userid));
            const userSnapshot = await getDocs(userQuery);

            if (!userSnapshot.empty) {
                setFeedback({ type: "error", message: "Userid already exists! Please choose a different userid." });
                return;
            }

            // Ensure the image is a JPG
            if (!image.name.endsWith('.jpg')) {
                setFeedback({ type: "error", message: "Only JPG images are allowed!" });
                return;
            }

            // Upload the image
            const storage = getStorage();
            const storageRef = ref(storage, 'profileImages/' + userid + '.jpg'); // Renaming the image
            const uploadTask = uploadBytesResumable(storageRef, image);

            uploadTask.on('state_changed',
                (snapshot) => {
                    // Handle the progress of the upload here if needed
                },
                (error) => {
                    setFeedback({ type: "error", message: "Error uploading image" });
                },
                async () => {

                    const userObj = {
                        alias: alias,
                        userid: userid,
                        pinCode: pinCode,
                        memberSince: new Date(),
                        membership: membership,
                        totalScore: 0
                    };

                    // Define the userDocRef here
                    const userDocRef = doc(usersCollection, userid);

                    try {
                        await setDoc(userDocRef, userObj);
                        setFeedback({ type: "success", message: "User added successfully!" });

                        // Add new user to the local users list
                        setUsers(prevUsers => [...prevUsers, userObj]);

                        closeModal(); // Close the modal after successful save
                    } catch (error) {
                        setFeedback({ type: "error", message: "Error adding user!" });
                    }
                }
            );
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const userCollection = collection(db, 'users');
            const userQuery = query(userCollection, orderBy('alias'));
            const snapshot = await getDocs(userQuery);
            const items = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            setUsers(items);
        };

        fetchData();
    }, []);

    return (
        <div className="admin-container">
<div className="admin-buttons">
    <Link to="/timebadge-admin" className="admin-button">
        <FontAwesomeIcon icon={faClock} />
        Timebadge
    </Link>

    <Link to="/admin-user" className="admin-button">
        <FontAwesomeIcon icon={faUser} />
        Memebers
    </Link>
    <Link to="/badge-admin" className="admin-button">
        <FontAwesomeIcon icon={faUser} />
        Badges
    </Link>
</div>



      <p className="helvetica-regular-normal-24px">Happy Bellies medlemmer</p>
            <button className="edit-button" onClick={() => openModal('add')} style={{margin: '20px'}}>Legg til medlem</button>
      {users.map(user => (
        <div key={user.id} className="user-item">
          <div className="user-details">
            <img 
              className="user-avatar"
              src={`https://firebasestorage.googleapis.com/v0/b/happybellies.appspot.com/o/profileImages%2F${user.userid}.jpg?alt=media`} 
              alt={user.alias}
            />
            <div>
              <p className="helvetica-regular-normal-12px">{user.userid}</p>
              <h3 className="helvetica-regular-normal-14px">{user.alias}</h3>
            </div>
          </div>
         <button className="edit-button" onClick={() => handleEditClick(user)}>Edit</button>

        </div>
      ))}

     <ReactModal 
        isOpen={isModalOpen} 
        onRequestClose={closeModal}
        className="Modal"
        overlayClassName="Overlay"
      >
        <div className="modal-content">
<div className="avatar-placeholder" style={{ backgroundImage: `url(${imagePreview})` }}>
  <input type="file" required hidden ref={imageInput} onChange={handleImageChange} />
  <FontAwesomeIcon style={{ color: 'white' }} size="2x" icon={faCamera} onClick={() => imageInput.current.click()} />
</div>

          <form onSubmit={handleSave} className="modal-form">
            <label>
              Alias:
              <input type="text" name="alias" required value={alias} onChange={e => setAlias(e.target.value)} />

            </label>
            <label>
              Bruker ID:
             <input 
    type="text" 
    name="userid" 
    required 
    value={userid} 
    onChange={e => setUserId(e.target.value)} 
    disabled={editMode} 
/>


            </label>
            <label>
              Pinkode:
              <input 
    type="text" 
    name="pinCode" 
    pattern="\d{1,4}" 
    maxLength="4" 
    required 
    value={pinCode} 
    onChange={e => setPincode(e.target.value)} 
    title="Please enter a number up to 4 digits long."
/>
            </label>
            <div className="input-group">
    <label htmlFor="membership" className="checkbox-label">
        <input 
            type="checkbox" 
            id="membership" 
            name="membership" 
            checked={membership} 
            onChange={handleCheckboxChange} 
        />
        <span className="custom-checkbox"></span>
        Medlemskap Betalt
    </label>
</div>
{feedback.message && (
    <div className={`feedback-${feedback.type}`}>
        {feedback.message}
    </div>
)}




            <button type="submit" className="akriver-btn">Arkiver</button>
          </form>
        </div>
      </ReactModal>

    </div>
    );
}

export default AdminUser;