import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged, setPersistence, browserSessionPersistence, signInWithEmailAndPassword } from "firebase/auth";
import './AdminLogin.css';

const AdminLogin = ({ setUser, email, setEmail, password, setPassword, handleLogout, setLoading, loading, auth }) => {
    const navigate = useNavigate();
    const [error, setError] = useState(null);

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            await setPersistence(auth, browserSessionPersistence);
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const { user } = userCredential;

            if (user.email === "runar@finanger.com" || user.email === "dan.aksel@gmail.com" || user.email === "pometass@gmail.com") {
                localStorage.setItem('isAdmin', true);
                setUser(user);
                navigate("/timebadge-admin");
            } else {
                localStorage.setItem('isAdmin', false);
                setError("Not authorized as admin.");
            }
        } catch (error) {
            setError(`Failed to sign in: ${error.message}`);
        }

        setLoading(false);
    };

 if (loading) {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <div className="spinner"></div>
    </div>
  );
}


    return (
        <div className="happyAdmin-container">
      <div className="happyAdmin-box">
        <h2><img width="190px" src="../img/happybellies_logo_orange.svg" /></h2>
        <div className="or-section">
          <span>LISENCE TO SWIM</span>
        </div>
        <form onSubmit={handleLogin}>
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
          />
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
          <button type="submit">
            LOG IN
          </button>
          {error && <div className="error-message">{error}</div>}
        </form>
      </div>
    </div>
    );
}

export default AdminLogin;