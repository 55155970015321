// NotificationContent.jsx
import React from 'react';

const NotificationContent = ({ title, body, imageUrl }) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {imageUrl && <img src={imageUrl} alt="Notification" style={{ width: '50px', height: '50px', marginRight: '10px' }} />}
            <div>
                <strong>{title}</strong>
                <p>{body}</p>
            </div>
        </div>
    );
};

export default NotificationContent;
