import React from "react";
import "./Record1.css";

function Record1(props) {
  const { rank, alias, lastBathDate, totalScore, userid } = props;

  const safeAlias = alias || 'N/A';
  const offsetMinutes = new Date().getTimezoneOffset();
  const offsetMillis = offsetMinutes * 60 * 1000;
  const utc2OffsetMillis = 7200 * 1000;
  

  const safeLastBathDate = lastBathDate
    ? (() => {
      console.log("Last bath date (converted):", lastBathDate.toDate());

        const adjustedDate = new Date(lastBathDate.toDate().getTime() + offsetMillis + utc2OffsetMillis);
        return `${adjustedDate.getDate()}.${(adjustedDate.getMonth() + 1)}.${adjustedDate.getFullYear().toString().substr(-2)}`;
      })()
    : 'No date';

  const safeTotalScore = totalScore || 0;
  const safeUserId = userid || 'no-id';

  return (
    <div className="record">
      <div className="plassering valign-text-middle helvetica-regular-normal-12px">{rank}</div>
      <img className="bilde" src={`https://firebasestorage.googleapis.com/v0/b/happybellies.appspot.com/o/profileImages%2F${safeUserId}.jpg?alt=media`} alt="" />
      <div className="name valign-text-middle helvetica-regular-normal-12px">{safeAlias}</div>
      <div className="dato valign-text-middle helvetica-regular-normal-12px">{safeLastBathDate}</div>
      <div className="score valign-text-middle helvetica-bold-black-12px">{safeTotalScore}</div>
      <img className="skillelinje" src={`../toptenimg/line-5.svg`} alt="Skillelinje" />
    </div>
  );
}

export default Record1;
