import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Outlet, useNavigate, Navigate } from 'react-router-dom';
import DesktopAutoLayout from "./components/DesktopAutoLayout";
import TopplisteAutoLayout from "./components/TopplisteAutoLayout";
import BadgeGallery from "./components/BadgeGallery";
import Badelogg from './components/Badelogg';
import X404 from './components/X404';
import AdminLogin from './components/AdminLogin';
import TimebadgeAdmin from './components/TimebadgeAdmin';
import BadgeAdmin from './components/BadgeAdmin';
import { signOut, getAuth, onAuthStateChanged } from "firebase/auth";
import { auth } from './components/TopplisteAutoLayout/firebase';
import AdminRoute from './components/AdminRoute';
import AdminUser from './components/AdminUser';
import HappyGame from './components/HappyGame';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import NotificationContent from './components/NotificationContent'; // Import the custom component
import { db } from './components/TopplisteAutoLayout/firebase';
import { collection, addDoc } from 'firebase/firestore';


import mqtt from 'mqtt';
import "./App.css";


function App() {

    const [user, setUser] = useState(null); // null implies no user is logged in
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isAuthInitialized, setIsAuthInitialized] = useState(false);


    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            console.log("User state changed, user:", user);
            if (user) {
                setUser(user);
            } else {
                setUser(null);
            }
            setIsAuthInitialized(true);
            setLoading(false);
        });

        return () => {
            unsubscribe();
        };
    }, [setUser, setLoading]);

    const [message, setMessage] = useState(null);
    const [isNotificationPermissionGranted, setNotificationPermissionGranted] = useState(false);
    const [fcmToken, setFcmToken] = useState(null); // State for storing FCM token

    const saveTokenToDatabase = (token) => {
        // Reference to the 'fcm_tokens' collection
        const tokensRef = collection(db, "fcm_tokens");

        // Add a new document with the token
        addDoc(tokensRef, { token: token, createdAt: new Date() })
            .then(() => console.log("Token saved to database"))
            .catch((error) => console.error("Error saving token to database:", error));
    };


    useEffect(() => {
    if ('serviceWorker' in navigator && 'Notification' in window) {
        // Check if the Firebase service worker is already registered
        navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope')
            .then((existingRegistration) => {
                if (existingRegistration) {
                    // Firebase service worker is already registered, skip registration
                    console.log('Firebase service worker is already active, skipping registration.');
                    return;
                }

                // Register your custom service worker with the same scope
                navigator.serviceWorker.register(new URL('/firebase-messaging-sw.js', import.meta.url), { scope: '/firebase-cloud-messaging-push-scope' })
                    .then((registration) => {
                        console.log('Service Worker Registered', registration);

                        // Request notification permission for desktop devices
                        if (!isMobileDevice()) {
                            requestNotificationPermission(registration);
                        }
                    })
                    .catch((err) => console.error('Service Worker registration failed: ', err));
            })
            .catch((err) => console.error('Service Worker registration check failed: ', err));
    } else {
        console.log('Service Worker/Notifications not supported in this browser.');
    }
}, []);



    const requestNotificationPermission = (registration) => {
        Notification.requestPermission().then(permission => {
            if (permission === 'granted') {
                console.log('Notification permission granted.');
                initializeFirebaseMessaging(registration);
            } else {
                console.log('Notification permission denied.');
            }
        });
    };

    const initializeFirebaseMessaging = () => {
        const messaging = getMessaging();
        getToken(messaging, { vapidKey: 'BAISIOqAKttyzuled-43dpYljjOgQ0NiOjkbqOT-3pr1layTqmhzaReFhUiInc3Ych_NIv-d3LDopbeC0DFZ18c' })
            .then(currentToken => {
                if (currentToken) {
                    console.log('FCM Token:', currentToken);
                    setFcmToken(currentToken);
                    saveTokenToDatabase(currentToken);
                } else {
                    console.log('No registration token available. Request permission to generate one.');
                }
            })
            .catch(err => console.error('An error occurred while retrieving token. ', err));

        onMessage(messaging, (payload) => {
            console.log('Foreground message received: ', payload);
            toast(<NotificationContent 
                title={payload.notification.title}
                body={payload.notification.body}
                imageUrl={payload.notification.image}
            />);
        });
    };

    // This function can be used to detect mobile devices
    const isMobileDevice = () => {
        return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
    };




    const handleLogout = async () => {
        setLoading(true);
        try {
            console.log('Logging out...');
            await signOut(auth);
            console.log('Logged out.');
            sessionStorage.clear();
            // Remove any stored tokens or user information from local storage
            localStorage.removeItem("authToken");
            setUser(null);
            // navigate("/login"); // If you have navigation
        } catch (error) {
            console.error("Error signing out:", error);
        }
        setLoading(false);
    };


    const [sensorData, setSensorData] = useState({
        air_temperature: null,
        humidity: null,
        uv_index: null,
        water_temperature: null,
        date_time: null
    });

    const [lastUpdate, setLastUpdate] = useState(null);
    const [initialDataFetched, setInitialDataFetched] = useState(false);

    // Generate the list of images
    const images = Array.from({ length: 21 }, (_, i) => `/img/summer/header__${String(i).padStart(4, '0')}.jpg`);

    // Function to get a random image from the imported images
    function getRandomImage() {
        return images[Math.floor(Math.random() * images.length)];
    }

    // Declare state for the image
    const [imageSrc, setImageSrc] = useState(getRandomImage());

    useEffect(() => {
        const options = {
            username: process.env.REACT_APP_MQTT_USERNAME,
            password: process.env.REACT_APP_MQTT_PASSWORD,
            server: process.env.REACT_APP_MQTT_BROKER_URL
        };
        const MQTT_BROKER_URL = process.env.REACT_APP_MQTT_BROKER_URL;

        const client = mqtt.connect(MQTT_BROKER_URL, options);

        const fetchData = () => {
            client.publish('request_sensor_data', ''); // Publish an empty message to request the latest data
        };

        client.on('connect', () => {
            console.log('Connected to MQTT broker');
            client.subscribe('sensor_data', (err) => {
                if (!err) {
                    console.log('Subscribed to sensor_data topic');
                    fetchData(); // Fetch the latest data immediately after subscribing
                }
            });
        });

        client.on('message', (topic, message) => {
            if (topic === 'sensor_data') {
                console.log('Received message from MQTT broker');

                const msg = message.toString();
                const data = JSON.parse(msg);
                console.log('Received data:', data);

                // Store the received data in session storage
                sessionStorage.setItem('sensorData', JSON.stringify(data));

                // Update the image source whenever new data is received
                const newImageSrc = getRandomImage();

                // Combine all state updates into a single setState call
                setSensorData(prevState => ({
                    ...prevState, // spread the previous state
                    air_temperature: data.air_temperature,
                    humidity: data.humidity,
                    uv_index: data.uv_index,
                    water_temperature: data.water_temperature,
                    date_time: data.date_time,
                    imageSrc: newImageSrc
                }));

                const now = new Date();
                const formattedDateTime = sensorData.date_time ? `Oppdatert ${sensorData.date_time}` : 'Oppdatert ukjent tid';


                // Store the last update time in local storage
                localStorage.setItem('lastUpdate', formattedDateTime);
                setLastUpdate(formattedDateTime); // Update lastUpdate state
            }
        });

        fetchData();

        // Retrieve last update time from local storage on component mount
        const storedLastUpdate = localStorage.getItem('lastUpdate');
        if (storedLastUpdate) {
            setLastUpdate(storedLastUpdate);
        }

        // Clean up connection on unmount
        return () => {
            client.end();
        };
    }, [initialDataFetched]); // Dependency array

    // Function to format date/time as "Oppdatert DD.MM.YYYY kl HH:mm"
    const formatDate = (date) => {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        return `Oppdatert ${day.toString().padStart(2, '0')}.${month.toString().padStart(2, '0')}.${year} kl ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    };

    const temperature = parseFloat(sensorData.water_temperature);
    const formattedTemperature = !isNaN(temperature) ? temperature.toLocaleString('no-NO', { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + '°C' : null;


    const humidity_data = parseFloat(sensorData.humidity);
    const formatted_humidity = !isNaN(humidity_data) ? `${humidity_data.toFixed(0)}%` : null;

    const uvindex_data = parseFloat(sensorData.uv_index);
    const formatted_uvindex_data = !isNaN(uvindex_data) ? uvindex_data.toLocaleString('no-NO', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : null;

    const airtemp = parseFloat(sensorData.air_temperature);
    const formatted_airtemp = !isNaN(airtemp) ? `${airtemp.toFixed(0)}°C` : null;


    const temperatureTexts = [{
            range: [-5, 0],
            texts: ["Vuderer du seriøst...?", "Motstå de negative tankene!", "Du flyter opp igjen."],
        },
        {
            range: [0.1, 4],
            texts: ["Yaaaaay! Dette er kaldt.", "Folk blir absolutt imponert.", "«Vent ikke, bad ham!»"],
        },
        {
            range: [4.1, 8],
            texts: ["Litt meningsløst eller?", "Klarer torsken, klarer du.", "Som i skolebassenget."],
        },
        {
            range: [8.1, 12],
            texts: ["Avkjøling på rekordtid", "Mor ville sagt nei.", "Ut og imponere damene?"],
        },
        {
            range: [12.1, 15],
            texts: ["På Hellvik er det kaldere.", "«Brr.. kjempedeilig vann!»", "Ikke heeelt Alanya"],
        },
        {
            range: [15.1, 17],
            texts: ["«Du blir vant til det!»", "Kaldere på Fagerstrand.", "Må du, så må du."],
        },
        {
            range: [17.1, 18.5],
            texts: ["«Litt kaldt i starten...»", "Nå vi skulle grilla!", "Stupevennlig!"],
        },
        {
            range: [18.6, 19.5],
            texts: ["Nesoddsommer <3", "Dropp planer, velg stranda!", "Yes, det er sommer!"],
        },
        {
            range: [19.6, 20.5],
            texts: ["Nå koser vi oss!", "Morgenbad OG kveldsbad?", "Ikke noe å vente på!"],
        },
        {
            range: [20.6, 22],
            texts: ["Sleng deg i havet!", "Nesten som Syden!", "Selv Pedersen bader nå."],
        },
        {
            range: [22.1, 24],
            texts: ["Det ble en SÅNN sommer!", "Bahia Fjellstrandia!", "Har alle tissa samtidig?"],
        },
        {
            range: [24.1, 30],
            texts: ["Ingen avkjøling her!", "Kan du skru ned nå, Gud?", "Og DER fikk MDG rett!"],
        }
    ];

    const uvLevels = [{
            range: [0, 2.9],
            level: "LAV",
        },
        {
            range: [3, 5.9],
            level: "MOD",
        },
        {
            range: [6, 7.9],
            level: "HØY",
        },
        {
            range: [8, 10.9],
            level: "EXT",
        },
        {
            range: [11, Infinity],
            level: "EKSTREM",
        },
    ];


    const getRandomText = (temperature) => {
        const matchingRange = temperatureTexts.find(
            (item) => temperature >= item.range[0] && temperature <= item.range[1]
        );

        if (matchingRange) {
            const { texts } = matchingRange;
            const randomIndex = Math.floor(Math.random() * texts.length);
            return texts[randomIndex];
        }

        // Return a default text if no matching range is found
        return "Nesten som syden!";
    };

    const getUVtag = (uvindex_data) => {
        const matchingUV = uvLevels.find(
            (item) => uvindex_data >= item.range[0] && uvindex_data < item.range[1]
        );

        if (matchingUV) {
            const { level } = matchingUV;
            return level;
        }

        // Return a default text if no matching range is found
        return "LAV";
    };


    const tickerMobilSommerData = {
        fjellstrandAkkuratN1: "FJELLSTRAND AKKURAT NÅ",
    };

    const phrases = [
        'UTVIKLING I BADEKAMPEN:',
        'SJOKK-BAD PÅ NESODDEN:',
        'ANKLAGES FOR JUKS:',
        'POENGJAKTEN FORTSETTER:',
        'ALT OM BADEKAMPEN:',
        'VILLE TILSTANDER:',
        'ENDELIG TILBAKE I KAMPEN:',
        'ELLEVILL HAPPY BELLY:',
        'TSUNAMIVARSEL:',
        'SJOKKERENDE UTVIKLING:'
    ];

    function getRandomPhrase() {
        const randomIndex = Math.floor(Math.random() * phrases.length);
        return phrases[randomIndex];
    }


    const desktopAutoLayoutData = {
        frame5: imageSrc,
        fjellstrandVel: "/img/happy_bellies_fjellstrand_logo.svg",
        badetemperatur: "BADETEMPERATUR",
        x195C: formattedTemperature,
        oppdatert31052023Kl0823: sensorData.date_time ? `Oppdatert ${sensorData.date_time}` : 'Oppdatert ukjent tid',
        strandtemperatur: "SOLVEGGEN",
        x22C: formatted_airtemp,
        luftfuktighet: "LUFTFUKTIGHET",
        percent: formatted_humidity,
        uvIndeks: "UV-INDEKS",
        number: formatted_uvindex_data,
        hy: getUVtag(uvindex_data),
        spanText1: "Støtt Fjellstrand vels arbeid med å",
        spanText2: "opprettholde Nesoddens beste strand!",
        bliMedlemHer: "Bli medlem her",
        tickerMobilSommerProps: tickerMobilSommerData,
        nestenSomSyden: getRandomText(temperature),
        dagbladetHeadline: getRandomPhrase(),
        requestNotificationPermission,
        fcmToken

    };



    return ( <
        >
        <ToastContainer position="top-center" /> <
        Router >
        <Routes>
                {/* Specific routes */}
                <Route path="/top-ten-list/:alias" element={<Badelogg beachData={sensorData} fcmToken={fcmToken} requestNotificationPermission={requestNotificationPermission}/>} />
                <Route path="/top-ten-list/:alias/utmerkelser" element={<BadgeGallery />} />
                <Route path="/top-ten-list" element={<TopplisteAutoLayout requestNotificationPermission={requestNotificationPermission} fcmToken={fcmToken}/>} />
                <Route path="/desktop-auto-layout" element={<DesktopAutoLayout {...desktopAutoLayoutData} />} />
                {/* Game route */}
                <Route path="/game" element={<HappyGame />} />

                {/* Admin routes */}
                <Route path="/happylogin" element={
                    <AdminLogin
                        user={user}
                        setUser={setUser}
                        email={email}
                        setEmail={setEmail}
                        password={password}
                        setPassword={setPassword}
                        handleLogout={handleLogout}
                        setLoading={setLoading}
                        loading={loading}
                        auth={auth}
                    />
                } />
                <Route path="/timebadge-admin" element={
                    <AdminRoute
                        element={<TimebadgeAdmin handleLogout={handleLogout} setLoading={setLoading} />}
                        user={user}
                        isAuthInitialized={isAuthInitialized}
                    />
                } />

                 <Route path="/badge-admin" element={
                    <AdminRoute
                        element={<BadgeAdmin handleLogout={handleLogout} setLoading={setLoading} />}
                        user={user}
                        isAuthInitialized={isAuthInitialized}
                    />
                } />

                {/* Add the AdminUser route here */}
                <Route path="/admin-user" element={
                    <AdminRoute
                        element={<AdminUser handleLogout={handleLogout} setLoading={setLoading} />}
                        user={user}
                        isAuthInitialized={isAuthInitialized}
                    />
                } />

                {/* Root route */}
                <Route path="/" element={<DesktopAutoLayout {...desktopAutoLayoutData} />} />

                {/* 404 specific route */}
                <Route path="/404" element={<X404 />} />

                {/* Fallback route for 404 (this should always be at the end) */}
                <Route path="*" element={<X404 />} />
            </Routes> <
        /Router> < / >
    );



}

export default App;