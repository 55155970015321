import React, { useState, useEffect, useRef, useCallback } from "react";
import TickerMobilSommer from "../TickerMobilSommer";
import "./DesktopAutoLayout.css";
import Modal from 'react-modal';
import mqtt from 'mqtt';
import MyModal from '../../components/MyModal';
import { db } from '../TopplisteAutoLayout/firebase';
import { onSnapshot, collection, where, query, doc, getDocs, getDoc, orderBy } from "firebase/firestore";
import { signInAnonymously } from "firebase/auth";
import { auth } from '../TopplisteAutoLayout/firebase';
import { Link } from 'react-router-dom';
import { fetchTideData } from '../../services/TideService'; // Adjust the path as needed



Modal.setAppElement('#app')
const ensureAnonymousSignIn = async () => {
    if (!auth.currentUser) {
        await signInAnonymously(auth);
    }
}


function DesktopAutoLayout(props) {
    const {
        frame5,
        fjellstrandVel,
        badetemperatur,
        x195C,
        nestenSomSyden,
        oppdatert31052023Kl0823,
        strandtemperatur,
        x22C,
        luftfuktighet,
        percent,
        uvIndeks,
        number,
        hy,
        spanText1,
        spanText2,
        bliMedlemHer,
        tickerMobilSommerProps,
        setTickerData,
        dagbladetHeadline,
        requestNotificationPermission,
        fcmToken
    } = props;

    const phrases = [
        'Nesodding bader i {badeTemp} og tar {badeScore} nye poeng',
        'Gal tjukkas bader i {badeTemp} og får {badeScore} friske poeng',
        'SISTE: Badet i vanvittige {badeTemp} og fikk {badeScore} poeng',
        'Imponerte: - Å bade i {badeTemp} er en lek',
        'Badet i {badeTemp} og tok {badeScore} nye poeng',
        'Snakker ut: - Nesten for varmt med {badeTemp} i vannet',
        'Badesjokk: Badet i {badeTemp} – fikk kun {badeScore} poeng',
        '- Det er {badeTemp} i vannet, men jeg får kun {badeScore} poeng?!',
        'Tjukkasen badet kl {badeTid} — fikk med seg {badeScore} poeng',
        'STRAKS PRESSEKONFERANSE: Happy Belly badet i {badeTemp}',
        'Tok svalestup i havet – håvet inn {badeScore} poeng',
        'Avlyste sydentur for å bade på Nesodden i {badeTemp}!',
        'Tok {badeScore} poeng kl {badeTid}: - Helt rått! ',
        'VITNER FIKK NAKENSJOKK: Skal ha nakenbadet kl {badeTid}',
    ];

    const [isImageLoaded, setImageLoaded] = useState(false);


    function getRandomPhrase(badeTemp, badeScore) {
        const randomIndex = Math.floor(Math.random() * phrases.length);
        return phrases[randomIndex]
            .replace('{badeTemp}', `<span class='helveticaneue-bold-white-17px'>${badeTemp}</span>`)
            .replace('{badeScore}', `<span class='helveticaneue-bold-white-17px'>${badeScore}</span>`)
            .replace('{badeTid}', `<span class='helveticaneue-bold-white-17px'>${badeTid}</span>`);

    }

    const [latestBathData, setLatestBathData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const docRef = doc(db, "LatestBath", "fixedDocumentId");
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setLatestBathData(docSnap.data());
            }
        };

        fetchData();
    }, []);

    const extractTime = (timestampInSeconds) => {
        const date = new Date(timestampInSeconds * 1000); // Convert to milliseconds
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    const badeTemp = latestBathData ? `${latestBathData.waterTemperature}°C` : "...";
    const badeScore = latestBathData ? `${latestBathData.updatedBathScore}` : "...";
    const badeTid = latestBathData ? extractTime(latestBathData.timestamp) : "...";

    const formattedBadeTemp = badeTemp.replace('.', ',');
    const randomPhraseHTML = `<span class='helveticaneue-regular-white-17px'>${getRandomPhrase(formattedBadeTemp, badeScore)}</span>`;
    const [modalIsOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true);
    }

    const closeModal = () => {
        setIsOpen(false);
    }


    const [activeBadge, setActiveBadge] = useState(null);
    const [initialSecondsRotation, setInitialSecondsRotation] = useState(0);
    const [initialArmRotation, setInitialArmRotation] = useState(0);
    const [remainingTime, setRemainingTime] = useState(0);

    function countdown() {
        setRemainingTime(prevTime => (prevTime > 0 ? prevTime - 1000 : 0));
    }
    useEffect(() => {
        // This will setup the countdown to decrease the remaining time every second
        const timerId = setInterval(countdown, 1000);

        // Cleanup interval when component unmounts or when the countdown reaches 0
        return () => clearInterval(timerId);
    }, [remainingTime]); // Dependency array to ensure effect runs every time remainingTime changes



    // Ref to track if the component is mounted
    const isMounted = useRef(true);
    useEffect(() => {
        ensureAnonymousSignIn();
    }, []);

    const fetchAndUpdateBadgeData = useCallback(async () => {


        try {
            const now = new Date();
            const startDateQuery = query(
                collection(db, 'timebadges'),
                where('start', '<=', now),
                orderBy('start', 'desc')
            );

            const snapshot = await getDocs(startDateQuery);



            if (!snapshot.empty) {
                const badgeData = snapshot.docs[0].data();
                const badgeStartTime = badgeData.start.toDate();
                const badgeEndTime = new Date(badgeStartTime.getTime() + badgeData.minutes * 60000);


                if (now >= badgeStartTime && now <= badgeEndTime) {
                    setActiveBadge(badgeData);

                    const elapsedTimeInMillis = now.getTime() - badgeStartTime.getTime();
                    const totalDurationInMillis = badgeData.minutes * 60000;
                    const remainingTimeInMillis = totalDurationInMillis - elapsedTimeInMillis;
                    setRemainingTime(remainingTimeInMillis);



                    // Calculate the angles for the second and minute arms based on the remaining time

                    // For the seconds arm:
                    const remainingSeconds = Math.floor((remainingTimeInMillis / 1000) % 60);
                    const initialSecondsDeg = (remainingSeconds / 60) * 360;


                    // For the minutes arm (which represents a 120-minute duration):
                    const remainingMinutes = Math.floor((remainingTimeInMillis / 60000) % 120);
                    const initialArmDeg = (remainingMinutes / 120) * 360;




                    console.log("Calculated initialSecondsDeg:", initialSecondsDeg);
                    console.log("Calculated initialArmDeg:", initialArmDeg);

                    // Update animation values if the component is still mounted
                    if (isMounted.current) {
                        setInitialSecondsRotation(initialSecondsDeg);
                        setInitialArmRotation(initialArmDeg);
                    }

                    const timeUntilBadgeEnds = badgeEndTime.getTime() - now.getTime();

                    const badgeTimeout = setTimeout(() => {
                        setActiveBadge(null);
                    }, timeUntilBadgeEnds);

                    return () => {
                        clearTimeout(badgeTimeout);
                    };
                } else {
                    setActiveBadge(null);
                }
            } else {
                setActiveBadge(null);
            }
        } catch (error) {
            console.error("Error fetching badge data:", error);
            // Optionally, you can also update some state to show an error message to the user
            // setError("There was an error fetching the badge data. Please try again later.");
        }
    }, []);

    // Fetch badge data and update animation values
    useEffect(() => {
            console.log("Component did mount / update");




            fetchAndUpdateBadgeData();

            // Component cleanup
            return () => {
                console.log("Component will unmount");
                isMounted.current = false;
            };

        },
        []);

    const [randomText, setRandomText] = useState(null);

    useEffect(() => {
        // Update the random text whenever the temperature or score changes, and include the formatting
        const newRandomText = `<span class='helveticaneue-regular-white-17px'>${getRandomPhrase(formattedBadeTemp, badeScore)}</span>`;
        setRandomText(newRandomText);
    }, [formattedBadeTemp, badeScore]);



    useEffect(() => {
        // This function will be called when the window gains focus
        function handleWindowFocus() {
            fetchAndUpdateBadgeData();
        }

        // Add the event listener
        window.addEventListener('focus', handleWindowFocus);

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('focus', handleWindowFocus);
        };
    }, [fetchAndUpdateBadgeData]);


    // Save updated animation values to local storage
    useEffect(() => {
        localStorage.setItem('secondsRotation', initialSecondsRotation.toString());
        localStorage.setItem('armRotation', initialArmRotation.toString());
    }, [initialSecondsRotation, initialArmRotation]);

    // Set initial values based on local storage when the component mounts
    useEffect(() => {
        const savedSecondsRotation = localStorage.getItem('secondsRotation');
        const savedArmRotation = localStorage.getItem('armRotation');

        if (savedSecondsRotation !== null && savedArmRotation !== null) {
            setInitialSecondsRotation(parseFloat(savedSecondsRotation));
            setInitialArmRotation(parseFloat(savedArmRotation));
        }
    }, []);

    // Tides logic

    const [tideData, setTideData] = useState([]);

    // Format tide time for display
    const formatTideTime = (tideTime) => {
        const date = new Date(tideTime);
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `kl ${hours}:${minutes}`;
    };


    const [loadingTideData, setLoadingTideData] = useState(false);

    useEffect(() => {
        const fetchTides = async () => {
            setLoadingTideData(true);
            try {
                const fromTime = new Date(new Date().getTime() - 16 * 60 * 60 * 1000).toISOString(); // Adjusted to start 16 hours earlier

                const toTime = new Date(new Date().getTime() + 48 * 60 * 60 * 1000).toISOString();

                const params = {
                    lat: '59.7923894',
                    lon: '10.6130338',
                    fromtime: fromTime,
                    totime: toTime,
                    datatype: 'tab',
                    refcode: 'cd',
                    lang: 'en',
                    interval: 60,
                    dst: 0,
                    tzone: 1,
                    tide_request: 'locationdata'
                };

                const fetchedData = await fetchTideData(params);
                 console.log("Fetched tide data:", fetchedData);

                const now = new Date();
                // Find the index of the last tide that has already occurred
                let mostRecentPastTideIndex = fetchedData.findIndex(tide => new Date(tide.time) > now) - 1;

                // Correcting for the scenario where all tides are in the future
                mostRecentPastTideIndex = Math.max(mostRecentPastTideIndex, 0);

                // Determine the slicing start index to ensure we include the most recent past tide
                let displayTides = fetchedData.slice(mostRecentPastTideIndex, mostRecentPastTideIndex + 4);

                // Edge case handling: If the calculation somehow results in less than 4 tides, adjust as needed
                if (displayTides.length < 4 && fetchedData.length >= 4) {
                    // This is an unlikely scenario but worth handling
                    displayTides = fetchedData.slice(0, 4);
                }

                setTideData(displayTides);
            } catch (error) {
                console.error('Error fetching tide data:', error);
            } finally {
                setLoadingTideData(false);
            }
        };


        fetchTides();
        const interval = 24 * 60 * 60 * 1000; // Fetch new data every 24 hours
        const intervalId = setInterval(fetchTides, interval);

        return () => clearInterval(intervalId);
    }, []);



    // displaying the next 4 tides
    const nextFourTides = tideData.slice(0, 4);

    return (
        <div className="desktop-auto-layout screen">
      <div className="frame-6">
        <div className="frame-1">
          <div className="frame-5" style={{ backgroundImage: `url(${frame5})` }}>
      <div className="overlay-content">
            {activeBadge ? (
                <>
                  
                  <img src="/img/timebadge/timebadge-background.jpg" alt="Timebadge Background" className="timebadge-background" />
                  <div className="timebadge-score">{activeBadge.score}</div>
                  <img
  src="/img/timebadge/timebadge-seconds.png"
  alt="Timebadge Seconds"
  className="timebadge-seconds"
  style={{
    transform: `translate(-50%, -50%) rotate(${initialSecondsRotation}deg)`,
    backgroundImage: 'url("/img/timebadge/timebadge-seconds.png")',
    '--initialRotation': `${initialSecondsRotation}deg`
  }}
/>

                 <img
  src="/img/timebadge/timebadge-arm.png"
  alt="Timebadge Arm"
  className="timebadge-arm"
  style={{
    transform: `translate(-50%, -50%) rotate(${initialArmRotation}deg)`,
    backgroundImage: 'url("/img/timebadge/timebadge-arm.png")',
    '--initialRotation': `${initialArmRotation}deg`
  }}
/>
                  <img src="/img/timebadge/timebadge-overlay.png" alt="Timebadge Overlay" className="timebadge-overlay" />
                 
                </>
            ) : (
                <>
    
                </>

            )}
             <img src={fjellstrandVel} alt="Fjellstrand" className="fjellstrand-vel-img" />
                    <div className="text-link">
                        <button onClick={openModal}><img src="/img/happy_burger.svg" alt="happy burger" /></button>
                    </div>
        </div>
</div>

        </div>
      </div>
      <div className="scroller">
<TickerMobilSommer 
    fjellstrandAkkuratN1={tickerMobilSommerProps.fjellstrandAkkuratN1} 
    remainingTime={remainingTime} 
    score={activeBadge ? activeBadge.score : null}
/>
      </div>
      <div className="badetemperatur">
        <div className="b-temp-f1">
          <img className="sea-icon" src="/img/swim_icon.svg" alt="Sea icon" />
          <div className="b-temp-f2">
            <div className="badetemperatur-1">{badetemperatur}</div>
            <h1 className="x195-c">{x195C}</h1>
            <div className="nesten-som-syden">{nestenSomSyden}</div>
          </div>
        </div>
      </div>
      <div className="bottom2">
        

          <div className="strandtempt">
          <div className="andre-data">
          <div className="info-block">
            <img className="tempIcons" src="/img/temperature-half-1-1.svg" alt="Temperature-half 1" />
            <div className="text-group">
              <div className="tempText helveticaneue-regular-normal-white-10px">{strandtemperatur}</div>
              <div className="tempNumbers helveticaneue-bold-white-32px">{x22C}</div>
            </div>
          </div>
          <div className="info-block">
            <img className="tempIcons" src="/img/water-drop-1-1.svg" alt="Water-drop 1" />
            <div className="text-group">
              <div className="tempText helveticaneue-regular-normal-white-10px">{luftfuktighet}</div>
              <div className="tempNumbers helveticaneue-bold-white-32px">{percent}</div>
            </div>
          </div>
          <div className="info-block">
            <img className="tempIcons" src="/img/sun-3-1-1.svg" alt="icon-brightness" />
            <div className="text-group">
              <div className="tempText helveticaneue-regular-normal-white-10px">{uvIndeks}</div>
              <div className="overlap-group">
                <div className="tempNumbers helveticaneue-bold-white-32px">
                  {number}<span className="hy helveticaneue-regular-normal-white-16px">{hy}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tide-display-container">
  <div className="tide-title helveticaneue-regular-normal-white-10px">TIDEVANN</div>
  <div className="tide-display">
    {nextFourTides.map((tide, index) => (
      <div key={index} className="tide-item">
        <img 
          src="/img/tide_arrow.svg" 
          alt={`${tide.flag} tide`}
          className={`tide-arrow ${tide.flag === 'low' ? 'low' : ''}`} 
        />
        <span className="tide-time">{formatTideTime(tide.time)}</span>
      </div>
    ))}
  </div>
</div>



        <div className="siste-fra-nesodden">
        <div className="sort-boks">
           <img 
    className="bildeNews" 
    src={`https://firebasestorage.googleapis.com/v0/b/happybellies.appspot.com/o/profileImages%2F${latestBathData?.userid}.jpg?alt=media`} 
    onLoad={() => setImageLoaded(true)}
    style={{ display: isImageLoaded ? 'block' : 'none' }}
    alt="Profile"
/>
            <p className="tekstDagbladet valign-text-middle">
                <span><span className="dagbladet helvetica-bold-yellow-17px">{dagbladetHeadline}<br /></span>

                <span dangerouslySetInnerHTML={{ __html: randomText }}></span>
                 </span>
            </p>

        </div>
        <div className="cta">
            <div className="helvetica-regular-normal-black-13px  toppliste-link"><Link to="/top-ten-list">SE TOPPLISTE OG BADERE </Link></div>
        </div>
                 <div className="oppdatert">
            <div className="oppdatert-31052023-kl-0823 helveticaneue-regular-normal-white-10px">
              {oppdatert31052023Kl0823}
            </div>
          </div>
    </div>

          </div>
        
        

      </div>

      <MyModal modalIsOpen={modalIsOpen} closeModal={closeModal} requestNotificationPermission={requestNotificationPermission}
            fcmToken={fcmToken} />

    </div>
    );
}

export default DesktopAutoLayout;