import React, { useState } from 'react';
import styles from './HallOfFameBadge.css';  

const HallOfFameBadge = ({ title, points, username, userid, index, onBadgeClick, record }) => {
    const [isFlipped, setIsFlipped] = useState(false);
    const colors = ['#383838', '#FFAC01', '#66D4B1', '#FB440E', '#4897BD', '#98709B'];
    const colorsBack = ['#9c9c9c', '#ffd580', '#b2e9d9', '#fda186', '#a3cbde', '#cbb7cd'];

    const badgeColor = colors[index % colors.length];
    const badgeBackColor = colorsBack[index % colorsBack.length];
    const imgSrc = userid ? `https://firebasestorage.googleapis.com/v0/b/happybellies.appspot.com/o/profileImages%2F${userid}.jpg?alt=media` : null;



    return (
        <div className="flip-card" onClick={() => setIsFlipped(!isFlipped)}>
            <div className={`flip-card-inner ${isFlipped ? 'flipped' : ''}`}>
<div className="flip-card-front" style={{ backgroundColor: badgeColor }}>
    <div className="info-container">
        <p className="hof_title helveticaneue-bold-white-16px ">{title}</p>
        <p className="points helveticaneue-regular-normal-white-16px">{points}</p>
    </div>
    {username && imgSrc ? <img src={imgSrc} alt={username} className="profilepicture" /> : null}
    <p className="username helvetica-regular-normal-white-14px">{username}</p>
</div>
<div className="flip-card-back" style={{ backgroundColor: badgeBackColor }}>
    <p className="hof_title helvetica-regular-normal-black-16px">Rekord å slå</p>
    <p className="points helvetica-regular-normal-14px">{record}</p>
</div>

            </div>
        </div>
    );
}
export default HallOfFameBadge;
