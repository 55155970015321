import React from "react";
import "./Top.css";
import { Link, useNavigate } from 'react-router-dom';

function Top({ openModal }) {
  return (

    <div className="top">
    <div className="frame-1">
    <div className="frame-5">
      <Link to="/"><img className="fjellstrand-vel-img" src="/img/happy_bellies_fjellstrand_logo.svg" alt="Happy Bellies of fjellstrand"/></Link>
            <div className="burger">
              <button onClick={openModal}> <img src="/img/happy_burger.svg" alt="happy burger"/></button>
            </div>
    </div>
    </div>
    </div>
  );
}

export default Top;

