import axios from 'axios';
import { XMLParser } from 'fast-xml-parser';


// The base URL for the tide API
const BASE_URL = 'https://api.sehavniva.no/tideapi.php';

/**
 * Fetches and processes tide data from the API.
 * 
 * @param {Object} params - Parameters for the API request including latitude, longitude, from and to time, etc.
 * @return {Promise<Array>} - An array of tide data objects.
 */
const fetchTideData = async (params) => {
    try {
        const response = await axios.get(BASE_URL, { params });
        const tideData = parseXMLTideData(response.data);
        return tideData;
    } catch (error) {
        console.error('Error fetching tide data:', error);
        throw error;
    }
};


/**
 * Parses the XML tide data into a JSON format.
 * 
 * @param {string} xmlData - The XML string from the API response.
 * @return {Array} - An array of tide data objects.
 */

const parseXMLTideData = (xmlData) => {
    try {
        const parserOptions = {
            ignoreAttributes: false, // Include the attributes of XML elements
            attributeNamePrefix : "" // Use an empty string to avoid prefixing attributes
        };
        const parser = new XMLParser(parserOptions);
        const result = parser.parse(xmlData);

        const waterLevels = result.tide.locationdata.data.waterlevel;
        return waterLevels.map(level => ({
            value: level.value, // Access the 'value' attribute
            time: level.time,   // Access the 'time' attribute
            flag: level.flag    // Access the 'flag' attribute
        }));
    } catch (error) {
        console.error('Error parsing XML data:', error);
        throw error;
    }
};





export { fetchTideData };
