import React from "react";
import "./FilterYear.css";

function FilterYear({ availableYears, onSelectYear, selectedYear, yearsUserExists }) {
  return (
    <div className="filter-year">
      {availableYears && availableYears.map((year, index) => {
        // If yearsUserExists is not provided, show all buttons; otherwise, conditionally show buttons.
        const shouldRenderButton = !yearsUserExists || yearsUserExists.includes(year);

        return shouldRenderButton && (
          <div 
            key={index} 
            className={year === selectedYear ? "aktiv-knapp helveticaneue-regular-normal-white-12px" : "inaktiv-knapp helveticaneue-regular-normal-gray-12px"}
            onClick={() => {
              if (year !== selectedYear) {
                onSelectYear(year);
              }
            }}
          >
            <div className="number valign-text-middle">{year}</div>
          </div>
        );
      })}
    </div>
  );
}


export default FilterYear;
