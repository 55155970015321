import React from "react";
import "./X404.css";
import x404Image from './404.jpg';
import { Link } from 'react-router-dom';


function X404() {

  return (
    <div className="x404" style={{ backgroundImage: `url(${x404Image})` }}>


      <p className="aiaiai-krvel-velle helveticaneue-regular-white-20px">
        <span className="helveticaneue-bold-white-50px">Aiaiai!</span>
        <br /><br />
        <span className="span1">Krøvel Velle Vold kan ikke finne siden du leter etter.</span>
      </p>
    </div>
  );
}

export default X404;
