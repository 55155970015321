import React from "react";
import { createRoot } from "react-dom/client";
import "./globals.css"
import "./styleguide.css"
import App from "./App";

const root = document.getElementById("app");
const reactRoot = createRoot(root);

reactRoot.render(<App />);
