import React, { useEffect, useRef } from 'react';
import Phaser from 'phaser';
import './HappyGame.css';

function HappyGame() {
    const gameRef = useRef(null);
    // Speed and increment values
    const initialGameSpeed = 300; // Adjusted to a lower initial speed
    const speedIncreaseInterval = 200; // Increase speed every 200 points


    const speedIncrement = 400; // Smaller speed increment for gradual increase
    const maxGameSpeed = 1800; // Higher maximum game speed

    /// Constants
    const BASE_JUMP_HEIGHT = 300; // Base jump height
    const JUMP_DURATION = 900; // Duration of the jump animation




    useEffect(() => {
        const isMobileDevice = () => {
            return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
        };

        const config = {
            type: Phaser.AUTO,
            width: 800,
            height: 600,
            parent: gameRef.current,
            physics: {
                default: 'arcade',
                arcade: {
                    gravity: { y: 0 }, // Adjust as needed
                    debug: false
                }
            },
            fps: {
                target: 60, // Target 60 frames per second
                forceSetTimeOut: false, // Rely on requestAnimationFrame
            },
            render: {
                pixelArt: true, // set to true if using pixel art which can help with blurriness
                roundPixels: true, // helps with sub-pixel rendering
                antialias: true // set to false if you want to disable antialiasing
            },
            scene: {
                preload: preload,
                create: create,
                update: update
            },
            scale: {
                mode: Phaser.Scale.FIT,
                autoCenter: Phaser.Scale.CENTER_BOTH,
                orientation: isMobileDevice() ? Phaser.Scale.LANDSCAPE : undefined,
                width: 1920,
                height: 1080,
            },
        };

        const game = new Phaser.Game(config);

        function preload() {
            this.load.spritesheet('Happybelly_man', 'sprites/happybelly_man_run.png', {
                frameWidth: 150,
                frameHeight: 220,
                endFrame: 5
            });
            this.load.audio('HappyBellies_theme', 'audio/Gospel_loop.mp3');
            this.load.audio('Gospel_end', 'audio/Gospel_end.mp3');
            this.load.audio('Jump_Hup', 'audio/Jump_Hup.wav');
            this.load.audio('Jump_Hoh', 'audio/Jump_Hoh.wav');
            this.load.image('HB_beach_tile', 'sprites/HB_beach_tile.jpg');
            this.load.image('HB_ocean', 'sprites/HB_ocean.jpg');
            this.load.image('HB_backdrop', 'sprites/HB_backdrop.jpg');
            // Load the object images
            this.load.image('sandcastle', 'sprites/objects/sandcastle.png');
            this.load.image('bucket', 'sprites/objects/bucket.png');
            this.load.image('solseng', 'sprites/objects/solseng.png');

            this.load.on('complete', () => {
                console.log('All assets loaded');
                this.assetsLoaded = true; // Set a flag indicating assets are loaded
            });
        }

        function calculateJumpDuration(gameSpeed) {
            console.log('Game Speed in Calculation:', gameSpeed);
            console.log('Initial Game Speed:', initialGameSpeed);
            console.log('Max Game Speed:', maxGameSpeed);

            const minDuration = 500; // Minimum jump duration
            const maxDuration = JUMP_DURATION; // Maximum jump duration

            // Ensure gameSpeed is defined
            if (typeof gameSpeed !== 'undefined') {
                const speedRatio = (gameSpeed - initialGameSpeed) / (maxGameSpeed - initialGameSpeed);
                return maxDuration - (speedRatio * (maxDuration - minDuration));
            } else {
                return maxDuration; // Default to maxDuration if gameSpeed is not set
            }
        }


        function create() {
            this.gameSpeed = initialGameSpeed;
            // Height of the ocean and beach tiles
            const oceanHeight = 119; // Adjust if different
            const beachHeight = 222; // Adjust if different

            // Create the backdrop
            this.backdrop = this.add.image(0, 0, 'HB_backdrop');
            this.backdrop.setOrigin(0, 0);
            this.backdrop.displayWidth = this.game.config.width;
            this.backdrop.displayHeight = this.game.config.height - (oceanHeight + beachHeight);

            // Create the ocean tile sprite
            this.oceanTile = this.add.tileSprite(0, this.game.config.height - (oceanHeight + beachHeight), this.game.config.width, oceanHeight, 'HB_ocean');
            this.oceanTile.setOrigin(0, 0);

            // Create the beach tile sprite
            this.beachTile = this.add.tileSprite(0, this.game.config.height - beachHeight, this.game.config.width, beachHeight, 'HB_beach_tile');
            this.beachTile.setOrigin(0, 0);

            //Scores
            this.score = 0;
            this.highScore = 0; // If you want to keep track of a high score

            // Create a text object to display the score
            this.scoreText = this.add.text(16, 16, 'score: 0', { fontSize: '64px', fill: '#000' });
            // Create a hidden Game Over text
            this.gameOver = false; // Flag to indicate if the game is over

            this.gameOverText = this.add.text((this.game.config.width / 2) - 300, this.game.config.height / 2, 'Game Over', { fontSize: '128px', fill: '#e35427' });
            this.gameOverText.setOrigin(0, 0);
            this.gameOverText.setVisible(false);

            this.happybellyMan = this.physics.add.sprite(250, 850, 'Happybelly_man');
            this.happybellyMan.setScale(.5);

            // Animation for running
            // Create the animation only if it doesn't already exist
            if (!this.anims.exists('run')) {
                this.anims.create({
                    key: 'run',
                    frames: this.anims.generateFrameNumbers('Happybelly_man', { start: 0, end: 2 }),
                    frameRate: 10,
                    repeat: -1
                });
            }

            // Create the Start Game text or button
            const startGameButton = this.add.text(this.game.config.width / 2, this.game.config.height / 2, 'Start Game', { font: '40px Arial', fill: '#ffffff' })
                .setInteractive({ useHandCursor: true })
                .setOrigin(0.5);

            startGameButton.on('pointerdown', () => {
                this.startGame();
            });

            this.startGame = () => {
                if (!this.assetsLoaded) {
                    console.log('Assets are still loading');
                    return; // Don't start the game if assets are not loaded
                }
                // Hide the Start Game button
                startGameButton.setVisible(false);
                this.gameStarted = true;
                this.scheduleNextSpawn();
                this.bgMusic = this.sound.add('HappyBellies_theme', { loop: true, volume: 1 });
                this.bgMusic.play();
                // Start the running animation
                this.happybellyMan.anims.play('run', true);



            };

            this.restartGame = () => {
                // Deactivate and fully reset all objects and their additional bodies
                this.objectsGroup.getChildren().forEach(object => {
                    object.setActive(false).setVisible(false);
                    object.body.setVelocityX(0); // Reset velocity if applicable
                    object.x = this.game.config.width; // Reset position off-screen for reuse

                    if (object.additionalBodies) {
                        object.additionalBodies.forEach(body => {
                            body.setActive(false).setVisible(false);
                            body.body.setVelocityX(0); // Reset velocity if applicable
                            // Reset other properties as needed
                        });
                    }
                });

                // Clear the objects group after resetting them
                this.objectsGroup.clear(true, true);

                // Remove and reset the spawn timer event
                if (this.spawnTimerEvent) {
                    this.spawnTimerEvent.remove(false);
                    this.spawnTimerEvent = null;
                }

                // Reset game-related variables and states
                this.score = 0;
                this.gameOver = false;
                this.gameStarted = false;
                this.gameSpeed = initialGameSpeed; // Reset game speed to initial value

                // Reset score text
                this.scoreText.setText('score: 0');

                // Hide the Game Over text
                this.gameOverText.setVisible(false);

                // Restart the entire Phaser scene
                this.scene.restart();

                // Optionally, restart the game logic immediately
                //this.startGame();
            };







            this.restartGameButton = this.add.text(this.game.config.width / 2, this.game.config.height / 2, 'Restart Game', { font: '40px Arial', fill: '#ffffff' })
                .setInteractive({ useHandCursor: true })
                .setOrigin(0.5)
                .setVisible(false);

            this.restartGameButton.on('pointerdown', () => {
                this.restartGame();
            });


            // Create a group for the objects
            this.objectsGroup = this.physics.add.group({
                maxSize: 10 // Limit the number of objects in the pool
            });
            // Define minimum and maximum delay values
            const minSpawnDelay = 800; // 1 second
            const maxSpawnDelay = 3000; // 3 seconds
            // Function to schedule the next spawn
            this.scheduleNextSpawn = () => {
                if (!this.gameStarted) return; // Ensure game has started

                const nextSpawnDelay = Phaser.Math.Between(minSpawnDelay, maxSpawnDelay);
                this.time.addEvent({
                    delay: nextSpawnDelay,
                    callback: this.spawnObject,
                    callbackScope: this,
                    loop: false
                });
            };



            this.spawnObject = () => {
                if (!this.gameStarted) { return };
                const lastObject = this.objectsGroup.getChildren().find(obj => obj.active);
                if (lastObject && lastObject.x > this.game.config.width - 400) {
                    // Last object is too close to the right edge, don't spawn a new one yet
                    return;
                }
                const objects = ['sandcastle', 'bucket', 'solseng'];
                const chosenObject = Phaser.Utils.Array.GetRandom(objects);

                // Define individual scale factors for each object
                const scaleFactors = {
                    sandcastle: 0.7, // example scale factor for sandcastle
                    bucket: .8, // example scale factor for bucket
                    solseng: 0.6 // example scale factor for solseng
                };

                console.log('Chosen object:', chosenObject); // Log to check the randomness


                let object = this.objectsGroup.getChildren().find(obj => !obj.active && obj.texture.key === chosenObject);

                if (!object) {
                    object = this.physics.add.sprite(this.game.config.width, this.game.config.height - 150, chosenObject);
                    this.objectsGroup.add(object);

                    if (chosenObject === 'solseng') {
                        this.createSolsengBodies(object);
                    } else if (chosenObject === 'sandcastle') {
                        this.createSandcastleBodies(object);
                    }
                } else {
                    object.setX(this.game.config.width); // Reset position
                }

                object.setActive(true).setVisible(true);
                object.setOrigin(0, 1);
                object.setScale(scaleFactors[chosenObject]);
                this.scheduleNextSpawn();
            };


            this.createSolsengBodies = (solseng) => {
                // Define offsets relative to the solseng sprite
                solseng.body.enable = false;
                const offsets = [
                    { x: 15, y: -60, width: 120, height: 50 },
                    { x: 10, y: -30, width: 150, height: 85 },
                    { x: 33, y: -73, width: 30, height: 20 }
                ];

                solseng.additionalBodies = offsets.map(offset => {
                    const body = this.physics.add.sprite(solseng.x + offset.x, solseng.y + offset.y, null);
                    body.body.setSize(offset.width, offset.height);
                    body.setOffset(offset.x, offset.y);
                    body.setVisible(false); // Hide if only for collision
                    return body;
                });
            };

            this.createSandcastleBodies = (sandcastle) => {
                // Disable the main sprite's body
                sandcastle.body.enable = false;

                // Define offsets and sizes for the additional bodies
                const offsets = [
                    { x: 35, y: -60, width: 40, height: 50 },
                    { x: 20, y: -40, width: 100, height: 85 }
                    // Add more if needed
                ];

                sandcastle.additionalBodies = offsets.map(offset => {
                    const body = this.physics.add.sprite(sandcastle.x + offset.x, sandcastle.y + offset.y, null);
                    body.body.setSize(offset.width, offset.height);
                    body.setOffset(offset.x, offset.y);
                    body.setVisible(false); // Hide if only for collision
                    return body;
                });
            };


            // Randomize the delay for the next spawn
            const nextSpawnDelay = Phaser.Math.Between(minSpawnDelay, maxSpawnDelay);

            // Periodically spawn objects
            this.time.addEvent({
                delay: nextSpawnDelay,
                callback: this.spawnObject,
                callbackScope: this,
                loop: false
            });

            // Set up collider between the player and the objects
            this.physics.add.collider(this.happybellyMan, this.objectsGroup, handleCollision, null, this);

            function handleCollision(player, object) {

                // Check if there is a collision
                const collision = this.physics.collide(player, object);

                if (collision) {
                    // Collision detected
                    this.gameOver = true;
                    this.happybellyMan.anims.stop();

                    // Stop the background music
                    if (this.bgMusic && this.bgMusic.isPlaying) {
                        this.bgMusic.stop();
                    }

                    // Play the game over sound without looping
                    this.sound.play('Gospel_end', { loop: false, volume: 1 });



                    // Get the current frame index
                    const currentFrameIndex = this.happybellyMan.anims.currentFrame.index;


                    const collisionFrameIndex = currentFrameIndex + 3; // Adjust the offset accordingly

                    // Set the sprite to the collision frame
                    this.happybellyMan.setFrame(collisionFrameIndex);

                    if (this.jumpTween) {
                        this.jumpTween.stop();
                        this.jumpTween = null;
                    }

                    this.gameOverText.setVisible(true);
                }
            }



            // Play the running animation
            //this.happybellyMan.anims.play('run', true);

            // Store the initial Y position (ground level)
            this.groundY = this.happybellyMan.y;

            this.jumpTween = null; // Initialize jumpTween

            // Add keyboard input for jumping
            this.spaceKey = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.SPACE);

            // Add touch event listener for mobile devices
            this.input.on('pointerdown', (pointer) => {
                pointer.event.preventDefault(); // Prevent default browser behavior
                console.log('Screen touched');
                if (!this.gameOver && (this.jumpTween === null || this.happybellyMan.y > this.groundY - 10)) {
                    this.performJump();
                }
            });




            // Mouse Input
            this.input.on('pointerdown', () => {
                if (!this.gameOver && (this.jumpTween === null || this.happybellyMan.y > this.groundY - 10)) {
                    this.startJumpTime = this.time.now;
                    this.isJumping = true;
                    this.performJump();
                }
            });
            this.input.on('pointerup', () => {
                this.isJumping = false; // End the jump on pointer up
            });

            // Space Key Input
            this.spaceKey.on('down', () => {
                if (!this.gameOver && (this.jumpTween === null || this.happybellyMan.y > this.groundY - 10)) {
                    this.startJumpTime = this.time.now;
                    this.isJumping = true;
                    this.performJump();
                }
            });
            this.spaceKey.on('up', () => {
                this.isJumping = false; // End the jump on key up
            });

            // Variables to track jumping state
            this.jumpCount = 0;


            this.performJump = () => {
                if (this.gameOver || this.jumpTween) return;
                console.log('Jump gamespeed: ', this.gameSpeed);

                const dynamicJumpDuration = calculateJumpDuration(this.gameSpeed);
                console.log('Jump duration: ', dynamicJumpDuration);
                const dynamicJumpHeight = BASE_JUMP_HEIGHT * (initialGameSpeed / this.gameSpeed);
                console.log(dynamicJumpHeight);


                // Pause the running animation and set the jumping frame
                this.happybellyMan.anims.pause();
                this.happybellyMan.setFrame(1); // Set to the jump frame

                // Play the jump sound effect
                const jumpSound = this.sound.add('Jump_Hup');
                jumpSound.play();

                // Jump tween for a fixed jump height and duration
                this.jumpTween = this.tweens.addCounter({
                    from: 0,
                    to: BASE_JUMP_HEIGHT,
                    duration: dynamicJumpDuration / 2,
                    yoyo: true,
                    ease: 'Quad.easeOut',
                    onUpdate: tween => {
                        const value = tween.getValue();
                        this.happybellyMan.y = this.groundY - value;
                    },
                    onComplete: () => {
                        this.happybellyMan.anims.resume();
                        this.happybellyMan.setFrame(0); // Assuming frame 0 is the running frame
                        this.happybellyMan.y = this.groundY;
                        this.jumpTween = null;
                    }
                });
            };






            // Function to handle landing
            this.onLanding = () => {
                this.happybellyMan.anims.resume();
                this.happybellyMan.y = this.groundY;
                this.jumpCount = 0;
                this.jumpTween = null; // Reset the jump tween
            };



            // Start or extend jump on key down
            this.spaceKey.on('down', () => {
                if (this.jumpCount < 2 && (this.jumpCount === 0 || (this.canDoubleJump && this.jumpCount === 1))) {
                    this.jumpCount++;
                    this.performJump();
                }
            });

        }
        // Collision handling logic
        function handleCollision() {
            this.gameOver = true;
            this.happybellyMan.anims.stop();

            // Adjust frame for collision, ensure this logic matches your sprite setup
            const collisionFrameIndex = Math.min(this.happybellyMan.anims.currentFrame.index + 3, 5);
            this.happybellyMan.setFrame(collisionFrameIndex);
            // Stop the background music if it's playing
            if (this.bgMusic && this.bgMusic.isPlaying) {
                this.bgMusic.stop();
            }

            // Play the game over sound without looping
            this.sound.play('Gospel_end', { loop: false, volume: 1 });


            if (this.jumpTween) {
                this.jumpTween.stop();
                this.jumpTween = null;
            }
            // Stop spawning objects
            if (this.spawnTimerEvent) {
                this.spawnTimerEvent.remove(false);
                this.spawnTimerEvent = null;
            }

            this.gameOverText.setVisible(true);
            this.restartGameButton.setVisible(true); // Make sure the restart button is visible
        };


        const speedChangeThreshold = maxGameSpeed * 0.05; // 5% of maxGameSpeed



        function update(time, delta) {


            if (!this.gameStarted || this.gameOver) return;
            if (this.gameOver) {
                return; // Stop further updates if game is over
            }

            // Update game speed based on score or time
            const speedFactor = Math.log(this.score + 1) / Math.log(speedIncreaseInterval);
            this.gameSpeed = Math.min(initialGameSpeed + speedFactor * speedIncrement, maxGameSpeed);

            // Check if game speed has changed significantly to update jump duration
            if (!this.prevGameSpeed || Math.abs(this.gameSpeed - this.prevGameSpeed) > speedChangeThreshold) {
                this.jumpDuration = calculateJumpDuration.call(this);
                //console.log('Updated Jump Duration:', dynamicJumpDuration);
                this.prevGameSpeed = this.gameSpeed;
            }



            // Calculate the distance to move based on the game speed and delta time
            const deltaSeconds = delta / 1000;
            const distanceToMove = this.gameSpeed * deltaSeconds;

            // Update the position of the tiles
            this.beachTile.tilePositionX += distanceToMove;
            this.oceanTile.tilePositionX += distanceToMove / 6;


            // Increment the score every X frames
            if (!this.playerCollided) {
                if (!this.frameCount) {
                    this.frameCount = 0;
                }

                this.frameCount++;

                if (this.frameCount % 5 === 0) {
                    this.score++;
                    this.scoreText.setText(`score: ${this.score}`);
                }
            }

            this.objectsGroup.getChildren().forEach(object => {
                if (object.active) {
                    object.x -= distanceToMove; // Move the object with dynamic speed

                    // Update and check collision for additional bodies
                    if ((object.texture.key === 'solseng' || object.texture.key === 'sandcastle') && object.additionalBodies) {
                        object.additionalBodies.forEach(body => {
                            body.x = object.x + body.body.offset.x;
                            body.y = object.y + body.body.offset.y;

                            if (this.physics.collide(this.happybellyMan, body)) {
                                handleCollision.call(this); // Use .call to maintain context
                            }
                        });
                    }

                    // Remove the object if it moves off screen
                    if (object.x < -object.width) {
                        // Reset object and additional bodies
                        object.setActive(false).setVisible(false);
                        object.x = this.game.config.width; // Reset position off-screen for reuse

                        if (object.texture.key === 'solseng' && object.additionalBodies) {
                            object.additionalBodies.forEach(body => {
                                body.setActive(false).setVisible(false);
                                // Reset additional body positions relative to the main object
                                body.x = object.x + body.body.offset.x;
                                body.y = object.y + body.body.offset.y;
                            });
                        }
                    }
                }
            });

        }




        function resizeGame() {
            const gameCanvas = game.canvas;
            const windowWidth = window.innerWidth;
            const windowHeight = window.innerHeight;
            const windowRatio = windowWidth / windowHeight;
            const gameRatio = game.config.width / game.config.height;

            if (windowRatio > gameRatio) {
                gameCanvas.style.width = windowWidth + 'px';
                gameCanvas.style.height = (windowWidth / gameRatio) + 'px';
            } else {
                gameCanvas.style.height = windowHeight + 'px';
                gameCanvas.style.width = (windowHeight * gameRatio) + 'px';
            }
        }

        window.addEventListener('resize', resizeGame);
        window.addEventListener('orientationchange', resizeGame); // Added listener for orientation changes
        resizeGame(); // Call resizeGame initially to set the right size from the start

        return () => {
            window.removeEventListener('resize', resizeGame);
            window.removeEventListener('orientationchange', resizeGame); // Remove the orientation change listener
            game.destroy(true);
        };
    }, []);

    return <div id="game-container" ref={gameRef}></div>;
}

export default HappyGame;