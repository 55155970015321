import React from 'react';
import Modal from 'react-modal';
import "./modal.css";
import { Link } from 'react-router-dom';

Modal.setAppElement('#app')

function MyModal({ modalIsOpen, closeModal, requestNotificationPermission, fcmToken }) {
  
    // Check if the device is an iPhone
    const isIphone = /iPhone/.test(navigator.userAgent);

    // Check if the app is running in standalone mode on an iPhone
    const isIphoneStandalone = isIphone && ('standalone' in window.navigator) && window.navigator.standalone;

    // Check if the Notification API is available (for non-iPhone browsers)
    const isNotificationAPIAvailable = typeof Notification !== 'undefined';

    // Determine whether to show the notification link
    // Show on desktop browsers or when the iPhone app is in standalone mode
    // And only if the Notification permission is not granted
    const showNotificationLink = (isNotificationAPIAvailable && Notification.permission !== 'granted' && !fcmToken) && (!isIphone || isIphoneStandalone);

    return (
        <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="My dialog"
      className="mymodal"
      overlayClassName="myoverlay"
      closeTimeoutMS={500}
    >
      <button 
        onClick={closeModal} 
        className="text-link" 
        style={{ backgroundColor: 'transparent', border: 'none', color: 'black', textDecoration: 'underline' }}
      >
        LUKK
      </button>
      <div className="modal-content">
        <img className="modalLogo" src="/img/happybellies_logo_orange.svg" alt="Logo" />
        <div className="badebrikke-modal valign-text-middle  helvetica-regular-normal-black-16px"><Link to="/top-ten-list">Toppliste og badelogg</Link></div>
        <div className="badebrikke-modal valign-text-middle  helvetica-regular-normal-black-16px"><Link to="https://forms.gle/ZkP1t9TkQVdd1tM47">Kjøp badekode</Link></div>
        <div className="badebrikke-modal valign-text-middle  helvetica-regular-normal-black-16px"><Link to="/">Forsiden</Link></div>
        
 {showNotificationLink && (
                <div className="badebrikke-modal valign-text-middle helvetica-regular-normal-black-16px">
                    <Link to="#" onClick={requestNotificationPermission}>Aktiver varsel</Link>
                </div>
            )}

        <hr />
        <p>Møt Happy Bellies! Disse sjarmerende og struttende skapningene svømmer i skjul hele året, bringer deg den mest oppdaterte badetemperaturen, og byr på en god dose kroppspositivitet.</p>
        <p>Nå inviterer de deg til å ta del i Badekampen! Test motet ditt ved å bade i ulike temperaturer. Registrer hvert bad med koden din via badeloggen, samle poeng, og vær med i kampen om fantastiske premier. Poengene er basert på en hemmelig formel, men ett hint: kaldt vann gir deg et fortrinn. Alle inntektene går rett til premiene.</p>
        <p>Klar for en forfriskende utfordring med Happy Bellies? Vi sees på stranda!</p>
      </div>
    </Modal>
    );
}

export default MyModal;