import React, { useEffect, useState } from "react";
import "./TickerMobilSommer.css";

function TickerMobilSommer(props) {
    const { fjellstrandAkkuratN1, remainingTime, score } = props;

    const [contentWidth, setContentWidth] = useState(0);
    const marqueeRef = React.useRef(null);

    // Convert remainingTime (in milliseconds) to minutes and seconds
    const minutes = Math.floor(remainingTime / 60000);
    const seconds = ((remainingTime % 60000) / 1000).toFixed(0);
    const timeLeftString = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    const tickerClasses = remainingTime > 0 ? "ticker-mobil-sommer active-badge" : "ticker-mobil-sommer";

    // Conditionally construct the text to show in the ticker
    let textToShow;
    if (remainingTime > 0) {
        textToShow = `TIME BADGE AKTIV! ${score || 'N/A'} POENG - ${timeLeftString}`;
    } else {
        textToShow = fjellstrandAkkuratN1;
    }

    // Repeat the text 40 times with spaces after each repetition
  // Repeat the text 40 times with HTML non-breaking spaces after each repetition
const repeatedText = Array(40).fill(textToShow)
                      .map(text => `${text} ${Array(6).fill('&nbsp;').join('')}`)
                      .join('');


    useEffect(() => {
        if (marqueeRef.current) {
            setContentWidth(marqueeRef.current.scrollWidth);
        }
    }, [textToShow]);

    return (
        <div className={tickerClasses}>
            <div className="frame-1-1 helveticaneue-bold-white-16px">
                <div className="marquee" ref={marqueeRef}>
                    <span className="fjellstrand-akkurat-n valign-text-middle" dangerouslySetInnerHTML={{ __html: repeatedText }}></span>
                    <span className="fjellstrand-akkurat-n valign-text-middle" dangerouslySetInnerHTML={{ __html: repeatedText }}></span>
                </div>
            </div>
        </div>
    );
}

export default TickerMobilSommer;
