import React, { useEffect, useState } from "react";
import Top from "../Top";
import FilterYear from "../FilterYear";
import HallOfFameBadge from "../HallOfFameBadge";
import SearchUser from "../SearchUser";
import Record1 from "../Record1";
import "./TopplisteAutoLayout.css";
import Modal from 'react-modal';
import MyModal from '../../components/MyModal';
import { db } from './firebase';
import { onSnapshot, collection, query, orderBy, where, limit, doc, getDoc, getDocs } from "firebase/firestore";
import { Link, useNavigate } from 'react-router-dom';
import { signInAnonymously } from "firebase/auth";
import { auth } from '../TopplisteAutoLayout/firebase';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";



Modal.setAppElement('#app')

const breadcrumbsData = {
    hjemToppliste: "Hjem  > Toppliste",
};

const skMobilData = {
    skPFEksFaderv: "Søk på din favoritt Happy Belly",
};

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 2.33,
  slidesToScroll: 1,
  swipeToSlide: true,
  initialSlide: 0,
  arrows: false,  // Add this line to hide the arrows
};


const ensureAnonymousSignIn = async () => {
    if (!auth.currentUser) {
        await signInAnonymously(auth);
    }
}

function TopplisteAutoLayout({ requestNotificationPermission, fcmToken }) {
    const navigate = useNavigate();
    const [hasMore, setHasMore] = useState(true);
    const [isSearchActive, setIsSearchActive] = useState(false);


    useEffect(() => {
        ensureAnonymousSignIn();
    }, []);

    const [users, setUsers] = useState([]);
    const [loadLimit, setLoadLimit] = useState(20);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); //to keep track of selected year.

    //Search stuff
    const [filteredUsers, setFilteredUsers] = useState([]);

    // This function updates the ranks of the filtered users
    // based on their original ranks from the 'users' array.
    const updateRanks = (filteredUsers, allUsers) => {
        return filteredUsers.map((user) => {
            const originalUser = allUsers.find((u) => u.id === user.id);
            return {
                ...user,
                rank: originalUser ? originalUser.rank : null, // Retain the original rank
            };
        });
    };

const [hallOfFameData, setHallOfFameData] = useState([]);

useEffect(() => {
    const fetchHallOfFame = async () => {
        try {
            const currentYear = new Date().getFullYear();
            const collectionName = selectedYear === currentYear ? 'hallOfFame' : `hallOfFame-${selectedYear}`;
            const collectionRef = collection(db, collectionName);
            const querySnapshot = await getDocs(collectionRef);

            const fetchedData = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            setHallOfFameData(fetchedData);

        } catch (error) {
            console.log("Error fetching hallOfFame data: ", error);
        }
    };

    fetchHallOfFame();
}, [selectedYear]); // Re-fetch when selectedYear changes




    const handleSearch = (searchTerm) => {
        if (searchTerm === "") {
            setIsSearchActive(false);
            setFilteredUsers(users);
        } else {
            setIsSearchActive(true);
            const newFilteredUsers = users.filter(user =>
                user.alias.toLowerCase().includes(searchTerm.toLowerCase())
            );

            // Update the ranks of the filtered users
            const rankedFilteredUsers = updateRanks(newFilteredUsers, users);
            setFilteredUsers(rankedFilteredUsers);
        }
    };

    const [sortConfig, setSortConfig] = useState({
        key: 'totalScore', // default sorting is by totalScore
        direction: 'desc' // descending order
    });

const sortedUsers = React.useMemo(() => {
    let sortableUsers = [...filteredUsers];
    if (sortConfig !== null) {
        sortableUsers.sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'asc' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'asc' ? 1 : -1;
            }
            return 0;
        });
        
        // Filter out non-members
        sortableUsers = sortableUsers.filter(user => user.membership);
        
        // Assign ranks to remaining users after filtering
       // sortableUsers.forEach((user, index) => {
         //   user.rank = index + 1;
        //});
    }
    return sortableUsers;
}, [filteredUsers, sortConfig]);






    useEffect(() => {
        const fetchTopUsers = async () => {
            const currentYear = new Date().getFullYear();
            const collectionName = selectedYear === currentYear ? 'users' : `users-${selectedYear}`;

            // Create the query
            let q = query(
                collection(db, collectionName),
                orderBy("totalScore", "desc")
            );

            if (!isSearchActive) {
                q = query(q, limit(loadLimit));
            }

            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const usersData = [];
                let rank = 1; // Initialize rank
                querySnapshot.forEach((doc) => {
                    usersData.push({
                        ...doc.data(),
                        id: doc.id,
                        rank: rank // Add rank info here based on the original list
                    });
                    rank++; // Increment rank for the next user
                });
                setUsers(usersData);
                setFilteredUsers(usersData);
                if (usersData.length < loadLimit) {
                    setHasMore(false);
                }
            });

            return () => {
                unsubscribe(); // Unsubscribe when the component unmounts
            };
        };

        fetchTopUsers();
    }, [loadLimit, selectedYear, isSearchActive]); // Added isSearchActive as a dependency


    const handleSelectYear = (year) => {
        setSelectedYear(year);
    };


    // Add this state variable to track available years
    const [availableYears, setAvailableYears] = useState([]);


    useEffect(() => {
        const fetchYears = async () => {
            try {
                const docRef = doc(db, "meta", "yearMeta");

                const docData = await getDoc(docRef);

                if (docData.exists()) {
                    const data = docData.data();

                    setAvailableYears(data.years);


                } else {
                    console.log("No such document!");
                }

            } catch (error) {
                console.log("Error fetching document: ", error);
            }
        };

        fetchYears();
    }, []);


    const topplisteAutoLayoutData = {
        spanText1: "Toppliste ",
        spanText2: "Badekampen",
        klikkPEnBrukerF: "Klikk på en bruker for å se full statistikk.",
        happyBelly: "HAPPY BELLY",
        sisteBad: "SISTE BAD",
        poeng: "POENG",
        text: "Vis flere",
        text2: <React.Fragment>Vil du slenge deg med i Fjellstrands<br /> tøffeste badekonkurranse og eie rettighetene til egen sexylubne Happy Bellies-avatar?</React.Fragment>,
        kjpBadebrikke: "Kjøp badekode",
        breadcrumbsProps: breadcrumbsData,
        skMobilProps: skMobilData,

    };

    const {
        spanText1,
        spanText2,
        klikkPEnBrukerF,
        happyBelly,
        sisteBad,
        poeng,
        text,
        text2,
        kjpBadebrikke,
        breadcrumbsProps,
        skMobilProps,

    } = topplisteAutoLayoutData;

    const [modalIsOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true);
    }

    const closeModal = () => {
        setIsOpen(false);
    }

    function handleButtonClick() {
        window.location.href = "https://forms.gle/ZkP1t9TkQVdd1tM47";
    }




    return (
        <div className="toppliste-autolayout screen">
    <MyModal modalIsOpen={modalIsOpen} closeModal={closeModal} requestNotificationPermission={requestNotificationPermission} fcmToken={fcmToken} /><Top openModal={openModal} />
    <div className="top-list">
        <div className="breadcrumbs">
        <div className="hjem-toppliste helvetica-regular-normal-black-12px"><Link to="/" className="hjem-toppliste-link">Hjem</Link>  > Toppliste</div>
        </div>
      <div className="h1-intro_text2">
        <h1 className="title_topten">
          <span className="helvetica-regular-normal-24px">Toppliste </span><span className="helvetica-regular-normal-vermilion-24px  span0">Badekampen</span>
        </h1>
      
      </div>

     <FilterYear availableYears={availableYears} onSelectYear={setSelectedYear} selectedYear={selectedYear} />

<div className="h1-intro_text2 helvetica-regular-black-16px">Hall of fame</div>
 <div>
      
 
 <div className="badgeContainer">     
            <Slider {...settings}>
                {hallOfFameData.map((badge, index) => (
                    <HallOfFameBadge 
                        key={index}
                        title={badge.categoryName}
                        points={`${badge.score} poeng`}
                        username={badge.alias}
                        userid={badge.userid}  
                        index={index}
                        record={`${badge.record} ${badge.recordTale}`} // Combined record and recordTale
                    />
                ))}
            </Slider>
</div>

    

    </div>
    
 <div className="h1-intro_text2 topplisten-text helvetica-regular-black-16px">Topplisten</div>
      
     <SearchUser onSearch={handleSearch} />
        <div className="header-line">
          <div className="group-28">
            <div className="flex-row helvetica-regular-normal-vermilion-10px">
               <div 
    className="text-1" 
    onClick={(event) => {
      event.stopPropagation();
        if (sortConfig.key === "totalScore" && sortConfig.direction === "asc") {
            setSortConfig({ key: "totalScore", direction: "desc" });
        } else {
            setSortConfig({ key: "totalScore", direction: "asc" });
        }
    }}
>
    #
     {/* Container for the Sort Arrows */}
    <div className="arrow-container">
        {/* Arrow for Ascending Sort */}
        <img
            style={{ transform: 'rotate(180deg)' }} 
            src={sortConfig.key === "totalScore" && sortConfig.direction === "desc" ? "../../img/arrow_active.svg" : "../../img/arrow_inactive.svg"} 
            alt="Sort Ascending" 
        />

        {/* Arrow for Descending Sort */}
        <img 
            src={sortConfig.key === "totalScore" && sortConfig.direction === "asc" ? "../../img/arrow_active.svg" : "../../img/arrow_inactive.svg"} 
            alt="Sort Descending" 
        />
    </div>
</div>
              <div 
    className="happy-belly" 
    onClick={(event) => {
      event.stopPropagation();
        if (sortConfig.key === "alias" && sortConfig.direction === "asc") {
            setSortConfig({ key: "alias", direction: "desc" });
        } else {
            setSortConfig({ key: "alias", direction: "asc" });
        }
    }}
>
    HAPPY BELLY
     {/* Container for the Sort Arrows */}
    <div className="arrow-container">
        {/* Arrow for Ascending Sort */}
        <img
            style={{ transform: 'rotate(180deg)' }} 
            src={sortConfig.key === "alias" && sortConfig.direction === "asc" ? "../../img/arrow_active.svg" : "../../img/arrow_inactive.svg"} 
            alt="Sort Ascending" 
        />

        {/* Arrow for Descending Sort */}
        <img 
            src={sortConfig.key === "alias" && sortConfig.direction === "desc" ? "../../img/arrow_active.svg" : "../../img/arrow_inactive.svg"} 
            alt="Sort Descending" 
        />
    </div>
</div>

<div 
    className="siste-badTopp" 
    onClick={(event) => {
      event.stopPropagation();
        if (sortConfig.key === "lastBathTime" && sortConfig.direction === "asc") {
            setSortConfig({ key: "lastBathTime", direction: "desc" });
        } else {
            setSortConfig({ key: "lastBathTime", direction: "asc" });
        }
    }}
>
    {sisteBad}
     {/* Container for the Sort Arrows */}
    <div className="arrow-container">
        {/* Arrow for Ascending Sort */}
        <img
            style={{ transform: 'rotate(180deg)' }} 
            src={sortConfig.key === "lastBathTime" && sortConfig.direction === "asc" ? "../../img/arrow_active.svg" : "../../img/arrow_inactive.svg"} 
            alt="Sort Ascending" 
        />

        {/* Arrow for Descending Sort */}
        <img 
            src={sortConfig.key === "lastBathTime" && sortConfig.direction === "desc" ? "../../img/arrow_active.svg" : "../../img/arrow_inactive.svg"} 
            alt="Sort Descending" 
        />
    </div>
</div>

 <div 
    className="poengTopp2" 
    onClick={(event) => {
      event.stopPropagation();
        if (sortConfig.key === "totalScore" && sortConfig.direction === "asc") {
            setSortConfig({ key: "totalScore", direction: "desc" });
        } else {
            setSortConfig({ key: "totalScore", direction: "asc" });
        }
    }}
>
    POENG
     {/* Container for the Sort Arrows */}
    <div className="arrow-container">
        {/* Arrow for Ascending Sort */}
        <img
            style={{ transform: 'rotate(180deg)' }} 
            src={sortConfig.key === "totalScore" && sortConfig.direction === "desc" ? "../../img/arrow_active.svg" : "../../img/arrow_inactive.svg"} 
            alt="Sort Ascending" 
        />

        {/* Arrow for Descending Sort */}
        <img 
            src={sortConfig.key === "totalScore" && sortConfig.direction === "asc" ? "../../img/arrow_active.svg" : "../../img/arrow_inactive.svg"} 
            alt="Sort Descending" 
        />
    </div>
</div>
              
           
            </div>
          </div>
          <img className="line-5" src="../../toptenimg/line-5.svg" alt="Line 5" />
        </div>
{sortedUsers.map((user) => (
  <div 
    key={user.id} 
    onClick={() => {
      const sanitizedAlias = user.alias.replace(/\s+/g, '-').toLowerCase();
      navigate(`/top-ten-list/${sanitizedAlias}`, {
        state: { rfid2: user.id, rank2: user.rank, selectedYear: selectedYear }
      });
    }}
  >
    <Record1
      rank={user.rank}
      alias={user.alias}
      lastBathDate={user.lastBathTime}
      totalScore={user.totalScore}
      userid={user.userid}
    />
  </div>
))}





      </div>
     <div className="load-more">
  {hasMore && (
    <div className="button_visflere" onClick={() => setLoadLimit(prevLimit => prevLimit + 20)}>
      <div className="text valign-text-middle">{text}</div>
    </div>
  )}
  <p className="text-2 helvetica-regular-normal-14px">{text2}</p>
  <div className="cta-buttonTopp" onClick={handleButtonClick}><div className="kjop-badebrikke helveticaneue-regular-normal-white-16px ">{kjpBadebrikke}</div>
  </div>
  </div>

      
    </div>
    );

}

export default TopplisteAutoLayout;