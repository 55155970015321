import React, { useRef, useEffect, useState } from "react";
import { getDocs, query, where, doc, getDoc, addDoc, onSnapshot, collection, orderBy, limit } from "firebase/firestore";
import Top from "../Top";
import Record01 from "../Record01";
import "../ToppListeAutoLayout/TopplisteAutoLayout.css";
import "./badelogg.css";
import Modal from 'react-modal';
import MyModal from '../../components/MyModal';
import { db, authenticateAnonymously, storage, ref, uploadBytesResumable, getDownloadURL } from '../TopplisteAutoLayout/firebase';
import FilterYear from "../FilterYear";
import { useLocation, useParams, Link, useNavigate, navigate } from 'react-router-dom';
import { signInAnonymously } from "firebase/auth";
import { auth } from '../TopplisteAutoLayout/firebase';
import html2canvas from 'html2canvas';
import { toast } from 'react-toastify';



const showToast = (message, type) => {
    switch (type) {
        case 'info':
            toast.info(message);
            break;
        case 'error':
            toast.error(message);
            break;
        case 'warning':
            toast.warn(message);
            break;
        case 'success':
            toast.success(message);
            break;
        default:
            toast(message);
    }
}



Modal.setAppElement('#app')

const filterYearData = {
    inaktivKnapp1Props: { children: "2021" },
    inaktivKnapp2Props: { children: "2022" },
    inaktivKnapp3Props: { children: "2023", className: "aktiv-knapp" },
};
const ensureAnonymousSignIn = async () => {
    if (!auth.currentUser) {
        await signInAnonymously(auth);
    }
}

const currentYear = "2023";

function Badelogg({ beachData, fcmToken, requestNotificationPermission }) {
    const location = useLocation();
    const navigate = useNavigate();
    const { alias } = useParams(); // This is the URL format of alias, e.g. "dolly-dimples"
    const decodedAlias = alias.replace(/-/g, ' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

    const [rfid, setRfid] = useState(location && location.state ? location.state.rfid2 : '');
    const [userData, setUserData] = useState({});
    const [readings, setReadings] = useState([]);
    const [loadLimit, setLoadLimit] = useState(10);
    const rank = location && location.state ? location.state.rank2 : '';
    const [hasMore, setHasMore] = useState(true);

    // Register bath button code
    const flipContainerRef = useRef(null);
    const [showForm, setShowForm] = useState(false);
    const [isButtonFlipped, setIsButtonFlipped] = useState(false);
    const [hoveringButton, setHoveringButton] = useState(false); // Track if mouse is hovering over the button
    const inputRef = useRef(null);
    const [isPasswordApproved, setIsPasswordApproved] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const handleFlipClick = (event) => {
        // Check if the click is coming from the form or the input itself
        if (inputRef.current && (inputRef.current.contains(event.target) || inputRef.current === event.target)) {
            return; // Exit early, don't flip
        }

        setIsButtonFlipped(!isButtonFlipped);
        event.stopPropagation();
    };
    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                const currentTime = new Date();
                const dataTime = new Date(beachData.date_time); // assuming date_time is in a format parsable by Date

                const differenceInHours = (currentTime - dataTime) / (1000 * 60 * 60); // Difference in hours

                if (differenceInHours > 1) { // If data is more than an hour old
                    toast.info("Data kan være utdatert. Vennligst oppdater siden for ferske data!", {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
    }, [beachData]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (flipContainerRef.current && !flipContainerRef.current.contains(event.target) && isButtonFlipped) {
                setIsButtonFlipped(false); // Set it to unflipped
            }
        }


        // Attach the click event listener
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isButtonFlipped]);

    const handleFormClick = (event) => {
        event.stopPropagation();
    };

    const [isPasswordIncorrect, setIsPasswordIncorrect] = useState(false);


    const handlePasswordSubmit = (event) => {
        event.preventDefault(); // Prevent default form submission
        event.stopPropagation();
        setIsLoading(true);

        const enteredPassword = inputRef.current.value;

        // Explicitly parsing the values and then combining them
        const combinedData = {
            air_temperature: parseFloat(beachData.air_temperature),
            humidity: parseFloat(beachData.humidity),
            uv_index: parseFloat(beachData.uv_index),
            water_temperature: parseFloat(beachData.water_temperature),
            date_time: beachData.date_time,
            imageSrc: beachData.imageSrc, // assuming this is a string
            pinCode: enteredPassword,
            rfid: rfid,
            fcmToken: fcmToken

        };

        // Print the combined data to the console:
        console.log("Parsed and Combined Data to be sent:", combinedData);

        const resetInputAndFlip = (duration = 2000) => {
            setTimeout(() => {
                setIsButtonFlipped(false);
                if (inputRef && inputRef.current) {
                    inputRef.current.value = "";
                    inputRef.current.blur();
                }
            }, duration);
        };

        // Send the combinedData to your cloud function:

        fetch("https://us-central1-happybellies.cloudfunctions.net/addUserScore/", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(combinedData)
            })
            .then(response => {
                // Check for general fetch errors
                if (!response.ok) {
                    // Return the response object alongside the status text for further handling in the next `.then()`
                    return response.json().then(data => ({
                        statusCode: response.status,
                        body: data
                    }));
                }
                return response.json();
            })
            .then(data => {
                setIsLoading(false);
                if (data.status === 'approved') {
                    showToast("Nice :) Badet er godkjent!", 'success');
                    console.log("Approved");
                    inputRef.current.blur();
                    setIsPasswordApproved(true);
                    resetInputAndFlip();
                } else if (data.statusCode === 429) {
                    showToast("Cooldown periode er fortsatt aktiv. Du må vente 2 timer mellom hvert bad", 'info');
                    resetInputAndFlip(1000);
                    return;
                } else if (data.statusCode === 401) {
                    console.log("Wrong pin-code");
                    showToast("Feil pin-kode - prøv igjen", 'error');
                    setIsPasswordIncorrect(true);
                    setTimeout(() => {
                        setIsPasswordIncorrect(false);
                        inputRef.current.value = "";
                        inputRef.current.focus();
                        return;
                    }, 1500);
                } else if (data.statusCode === 422) {
                    showToast("Reload siden og forsøk på nytt. Badetemperaturen er mer enn en time gammel!", 'error');
                    resetInputAndFlip(1000);
                    return;
                } else {
                    console.error(data.body.error || "Unknown server error");
                    showToast("Ukjent server feil!");
                    resetInputAndFlip(1000);
                    return;
                }
            })
            .catch(error => {
                console.error("Error:", error);
                resetInputAndFlip();
                showToast("En feil oppstod. Reload siden og forsøk igjen", 'error');
            });
    };

    const initialState = location.state ? .selectedYear || new Date().getFullYear();
    const [selectedYear, setSelectedYear] = useState(initialState);
    const [yearsUserExists, setYearsUserExists] = useState([]);
    const [availableYears, setAvailableYears] = useState([]);

    const handleSelectYear = (year) => {
        setSelectedYear(year);
    };

    useEffect(() => {
        const checkUserExistenceForYears = async () => {
            const years = []; // This will hold the years where the user exists
            for (const year of availableYears) {
                const userDoc = year === new Date().getFullYear() ?
                    doc(db, "users", rfid) :
                    doc(db, `users-${year}`, rfid);

                const docSnapshot = await getDoc(userDoc);
                if (docSnapshot.exists()) {
                    years.push(year);
                }
            }
            setYearsUserExists(years);
        };

        if (rfid && availableYears.length > 0) {
            checkUserExistenceForYears();
        }
    }, [rfid, availableYears]);



    useEffect(() => {
        const fetchYears = async () => {
            try {
                const docRef = doc(db, "meta", "yearMeta");

                const docData = await getDoc(docRef);

                if (docData.exists()) {
                    const data = docData.data();

                    setAvailableYears(data.years);


                } else {
                    console.log("No such document!");
                }

            } catch (error) {
                console.log("Error fetching document: ", error);
            }
        };

        fetchYears();
    }, []);


    useEffect(() => {
        if (rfid !== '' && selectedYear) {
            const startOfYear = new Date(selectedYear, 0, 1);
            const endOfYear = new Date(selectedYear, 11, 31, 23, 59, 59);

            const q = query(
                collection(db, `scores/${rfid}/readings`),
                where("timestamp", ">=", startOfYear),
                where("timestamp", "<=", endOfYear),
                orderBy("timestamp", "desc"),
                limit(loadLimit)
            );

            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const readingsData = [];
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    readingsData.push({
                        ...data,
                        timestamp: data.timestamp.toDate()
                    });
                });

                if (readingsData.length < loadLimit) {
                    setHasMore(false);
                }

                setReadings(readingsData);
            });

            return () => unsubscribe();
        }
    }, [loadLimit, rfid, selectedYear]); // Don't forget to add selectedYear as a dependency




    useEffect(() => {
        if (isButtonFlipped) {
            inputRef.current.focus();
        }
    }, [isButtonFlipped]);


    useEffect(() => {
        ensureAnonymousSignIn();
    }, []);

    useEffect(() => {
        if (!rfid && decodedAlias) {
            fetchRfidByAlias(decodedAlias);
        } else if (rfid) {
            fetchUserByRfid(rfid);
        }
    }, [rfid, decodedAlias, selectedYear]);


    const fetchUserByRfid = (rfid) => {
        const currentYear = new Date().getFullYear();
        const collectionName = selectedYear === currentYear ? "users" : `users-${selectedYear}`;
        const userDoc = doc(db, collectionName, rfid);

        getDoc(userDoc)
            .then((docSnapshot) => {
                if (docSnapshot.exists()) {
                    const userData = docSnapshot.data();
                    setUserData(userData);

                    if (!userData.membership) {
                        console.log("User is not a member!");
                        navigate('/404');
                    }
                } else {
                    console.log("No such user!");
                    navigate('/404'); // assuming '/404' is your 404 route
                }
            })
            .catch((error) => {
                console.error("Error fetching user data: ", error);
            });
    };




    const fetchRfidByAlias = (alias) => {
        const currentYear = new Date().getFullYear();
        const collectionName = selectedYear === currentYear ? "users" : `users-${selectedYear}`;
        const usersRef = collection(db, collectionName);
        const q = query(usersRef, where("alias", "==", alias));

        getDocs(q).then((querySnapshot) => {
            if (!querySnapshot.empty) {
                querySnapshot.forEach((doc) => {
                    // Set the document ID (rfid) based on the matched alias
                    setRfid(doc.id);
                });
            } else {
                console.log("No user with such alias!");
                navigate('/404'); // assuming '/404' is your 404 route
            }
        });
    };





    //Modal handling
    //Modal constants
    const [modalIsOpen, setIsOpen] = useState(false);
    const [isModal2Open, setIsModal2Open] = useState(false);
    const [imageBlob, setImageBlob] = useState(null);

    const openModal = () => {
        setIsOpen(true);
    }

    const closeModal = () => {
        setIsOpen(false);
    }

    const openModal2 = () => {
        setIsModal2Open(true);
    };

    const closeModal2 = () => {
        setIsModal2Open(false);
    };

    //Social media share logic.

    const generateCanvas = async (divId) => {
        const targetDiv = document.getElementById(divId);

        if (!targetDiv) {
            console.error("Element not found:", divId);
            return;
        }

        // Additional options for generating a JPEG image
        const options = {
            // Use the jpeg format
            type: "jpeg",
            // Set the quality of the image (0 to 1)
            quality: 1.0, // Adjust the quality as needed
            useCORS: true // Ensure CORS headers are used when fetching resources
        };

        const canvas = await html2canvas(targetDiv, options);

        if (!canvas) {
            console.error("Failed to generate canvas");
            return;
        }

        return new Promise((resolve) => {
            canvas.toBlob(resolve, "image/jpeg"); // Specify image/jpeg as the MIME type
        });
    };




    const lagSkryteBilde = async () => {
        const blob = await generateCanvas('social');

        if (!blob) {
            console.error("No blob to upload.");
            return;
        }

        setImageBlob(blob); // Store the blob in the state
        setIsModal2Open(true); // Open the modal
    };

    const visUtmerkelser = () => {
        navigate('utmerkelser', { state: { rfid, decodedAlias, alias, selectedYear } });
    };

    const [formSubmitted, setFormSubmitted] = useState(false);

    const handleInputChange = (e) => {
        if (e.target.value.length === 4) {
            // Automatically submit the form when 4 digits are entered
            handlePasswordSubmit(e);
        }
    };

    const monthNames = ["jan", "feb", "mars", "april", "mai", "juni", "juli", "aug", "sept", "okt", "nov", "des"];

    const safeLastBathDate = userData.lastBathDate ?
        `${userData.lastBathDate.toDate().getDate()}. ${monthNames[userData.lastBathDate.toDate().getMonth()]}` :
        'No date';

    const safeFirstBathDate = userData.firstBath ?
        `${userData.firstBath.toDate().getDate()}. ${monthNames[userData.firstBath.toDate().getMonth()]}` :
        'No date';




    const handleKeyDown = (event) => {
        // Check if the pressed key is "Enter"
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent the default action (form submission in this case)
        }
    };




    return ( <
            >


            <
            MyModal modalIsOpen = { modalIsOpen } closeModal = { closeModal } requestNotificationPermission={requestNotificationPermission} fcmToken={fcmToken}
            /> < Top openModal = { openModal } / >
            <div className="userWrapper">
        <div className = "breadcrumbs" > <div className="hjem-toppliste helvetica-regular-normal-black-12px"><Link to="/" title="Going home">Hjem</Link>  > <Link to="/top-ten-list" title="Going to top-ten">Toppliste</Link> > {userData.alias}</div> < /div>
        <div className = "profile-branding" >
        <div 
    className="flip-container" 
    ref={flipContainerRef}
     onClick={handleFlipClick}
>
    <div 
    className={`flipper ${isButtonFlipped ? 'flipped' : ''}`}
    onTransitionEnd={() => {
        if (!isButtonFlipped) {
            setIsPasswordApproved(false); // reset the approved state
        }
    }}
>
        <div className="front">
            <img className="bilde2" src={`https://firebasestorage.googleapis.com/v0/b/happybellies.appspot.com/o/profileImages%2F${userData.userid}.jpg?alt=media`} alt="" />


        </div>
        <div className="back">
            <div className="content-wrapper">
{isLoading && <div className="pulse-effect"></div>}
<div className={`circle-btn ${isPasswordApproved ? 'success' : ''} ${isPasswordIncorrect ? 'incorrect-password' : ''}`}>
    {isPasswordApproved ? (
        <span className="ok-text">OK!</span>
    ) : (
        <img className="swim-icon" src="../img/swim_icon.svg" alt="" />
    )}
</div>

                           
<div className="pin-input-container" onClick={handleFormClick}>
    <form onSubmit={handlePasswordSubmit}>
        <input 
        ref={inputRef} 
        type="tel" 
        pattern="[0-9]*" 
        maxLength="4" 
        placeholder="PIN" 
        className={`masked-input ${isPasswordApproved ? 'approved' : ''}`}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}

    />
        <button type="submit" style={{ display: 'none' }}>Submit</button> {/* Invisible submit button */}
    </form>
</div>


            </div>
        </div>
    </div>
</div>


        <
        div className = "frame-623" >
        <h1 className="title2">{userData.alias}</h1> <
        div className = "badebelly-siden-210622 valign-text-middle helveticaneue-regular-normal-black-xxxs" >
        Badebelly siden { userData.memberSince ? .toDate().toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/\//g, '.') } <
        /div> < /
        div > <
        /div>

<div className="filterYearBadelogg">
<FilterYear
    availableYears={availableYears}
    onSelectYear={handleSelectYear}
    selectedYear={selectedYear}
    yearsUserExists={yearsUserExists}
/></div>


        </div>

<div className="bruker-autolayout">
  <header className="helvetica-regular-normal-24px headerStatistikk">
    <div className="badelogg">Badestatistikk <span style={{color: '#FB440E'}}>{selectedYear}</span></div>

  </header>
  <div className="totals-year">
    <div className="poeng-total">
      <div className="ng-2023 helvetica-regular-normal-vermilion-14px">Poeng:</div>
      <div className="number2 helvetica-regular-normal-black-14px">{userData.totalScore}</div>
    </div>
    {
      rank && (
        <div className="poeng-total">
          <div className="pl-2023 helvetica-regular-normal-vermilion-14px">Plassering:</div>
          <div className="number2 valign-text-middle helvetica-regular-normal-black-14px">{rank}</div>
        </div>
      )
    }

  </div>

      <div className="streakLongest">
      <div className="poeng-total">
      <div className="ng-2023 helvetica-regular-normal-vermilion-14px">Lengste streak:</div>
      <div className="number2 helvetica-regular-normal-black-14px">{userData.longestStreak} dager</div>
    </div>
    </div>

    <div className="one-pixel-line"></div>
    <div className="streakLongest">
      <div className="poeng-total">
        <div className="ng-2023 helvetica-regular-normal-vermillion-12px">Antall bad:</div>
        <div className="number2 helvetica-regular-normal-black-12px">{userData.totalBaths}</div>
      </div>
      <div className="poeng-total">
        <div className="ng-2023 helvetica-regular-normal-vermillion-12px">Kaldest:</div>
        <div className="number2 helvetica-regular-normal-black-12px">{userData.coldestBath}°C</div>
      </div>
      <div className="poeng-total">
        <div className="ng-2023 helvetica-regular-normal-vermillion-12px">Varmest:</div>
        <div className="number2 helvetica-regular-normal-black-12px">{userData.warmestBath}°C</div>
      </div>
    </div>
    <div className="streakLongest badStat">
      <div className="poeng-total">
        <div className="ng-2023 helvetica-regular-normal-vermillion-12px">Første bad:</div>
        <div className="number2 helvetica-regular-normal-black-12px">{safeFirstBathDate}</div>
      </div>
      <div className="poeng-total">
        <div className="ng-2023 helvetica-regular-normal-vermillion-12px">Siste bad:</div>
        <div className="number2 helvetica-regular-normal-black-12px">{safeLastBathDate}</div>
      </div>
    </div>





        <
        div className = "skrytebilde" >
        <div onClick={lagSkryteBilde} className="overlap-group-skrytebilde">
          <img className="camera-icon" src="../img/camera_icon.svg" alt="Camera Icon" />
          <div className="lag-skrytebilde valign-text-middle helvetica-regular-normal-14px">Lag skrytebilde</div>
        </div>

        <
        div onClick = { visUtmerkelser } className = "overlap-group-badges" >
        <img className="camera-icon" src="../img/badges_icon.svg" alt="Camera Icon" /> <
        div className = "lag-skrytebilde valign-text-middle helvetica-regular-normal-14px" > Vis utmerkelser < /div> < /
        div >


        <
        /div>


        <
        header className = "header" > <div className="badelogg">Badelogg</div> <
        /header> <
        div className = "header-line" >
        <div className="flex-row helvetica-regular-normal-vermilion-10px">
              <div className="dato2">DATO</div>
              <div className="kl">KL.</div>
              <div className="vann">VANN</div>
              <div className="luft">LUFT</div>
              <div className="poeng">POENG</div>
      </div> <
        img className = "line-5"
        src = "../../toptenimg/line-5.svg"
        alt = "Line 5" / >

        {
            readings.map((reading, index) => (
                <Record01
            key={index}
            id={reading.id}
            rdate={reading.timestamp}
            airTemp={reading.airTemp}
            waterTemp={reading.waterTemp}
            score={reading.bathScore}
            badgeCount={reading.badgeCount}
            streakFlag={reading.streakEarned}
            timeBadge={reading.timeBadgeEarned}
            newlyEarnedBadges={reading.newlyEarnedBadges}
            scoreDetails={reading.scoreDetails} 
          />
            ))
        } {
            hasMore && (
                <div className="load-more">
    <div className="button_visflere" onClick={() => setLoadLimit(prevLimit => prevLimit + 10)}>
      <div className="text valign-text-middle">Vis flere</div>
    </div>
  </div>
            )
        }


        <
        /div> <
        Modal isOpen = { isModal2Open } onRequestClose = { closeModal2 } contentLabel = "Image Dialog"
        className = "mymodal"
        overlayClassName = "myoverlay"
        closeTimeoutMS = { 500 } >
        <button 
    onClick={closeModal2} 
    className="text-link" 
    style={{ backgroundColor: 'transparent', border: 'none', color: 'black', textDecoration: 'underline' }}
  >
    LUKK
  </button> <
        div className = "modal-content modal-container" >
        <img className="modalLogo" src="/img/happybellies_logo_orange.svg" alt="Logo" /> <
        img src = { imageBlob ? URL.createObjectURL(imageBlob) : '' } alt = "Generated Image"
        style = { { maxWidth: '276px', maxHeight: '70vh' } } // Ensuring it fits the viewport
        />

        <
        div className = "text-social helvetica-regular-normal-12px" >
        <div>For å poste skrytebildet ditt på Facebook:</div> <
        div className = "helvetica-regular-normal-black-12px" > 1. Kopiere skrytebildet: < /div>
        Trykk og hold nede på bildet til en meny vises, og velg deretter "Kopier". < br / > <br />

        <
        div className = "helvetica-regular-normal-black-12px" > 2. Dele på Facebook: < /div>
        Opprett nytt innlegg, trykk og hold i tekstområdet til "Lim inn" - alternativ vises, og velg den. <
        /div> < /
        div > <
        /Modal>

        <
        div id = "social"
        className = "x4u585 screen" >
        <div className="overlap-group1-social">
      <div className="overlap-group-social">
        <img className="userglory" src={`https://firebasestorage.googleapis.com/v0/b/happybellies.appspot.com/o/profileImages%2F${userData.userid}.jpg?alt=media`} />
        <div className="rectangle-1"></div>
        <h1 className="title3 helvetica-regular-bold-24px">{userData.alias}</h1>
        <div className="score-poeng helvetica-regular-normal-24px">{userData.totalScore} poeng</div>
        {rank && (<div className="rank-plass helvetica-regular-normal-24px">{rank}.plass</div>)}
<p className="badet-sist2 helvetica-regular-normal-14px">
   Badet sist i deilige {readings.length > 0 ? parseFloat(readings[0].waterTemp).toFixed(1).replace('.', ',') : "N/A"}°C
</p>
      </div>
      <img className="happy-bellies-of-fjellstrand" src="../toptenimg/happy-bellies-of-fjellstrand.svg" alt="Happy Bellies of fjellstrand"/>
    </div> <
        /div>

        <
        /div>

        <
        />
    );
}


export default Badelogg;